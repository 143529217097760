import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "wizard-body-subscription" }
const _hoisted_2 = { class: "wizard-wrapper" }
const _hoisted_3 = { class: "wizard-header" }
const _hoisted_4 = { class: "wizard-logo" }
const _hoisted_5 = { class: "wizard-content" }
const _hoisted_6 = { class: "wizard-card" }
const _hoisted_7 = { class: "wizard-card-content" }
const _hoisted_8 = { class: "wizard-forms-wrapper grid grid-nogutter align-content-center align-items-center justify-content-center" }
const _hoisted_9 = { class: "col-10 md:col-5" }
const _hoisted_10 = { style: {"background-color":"#0B2142","border-radius":"30px"} }
const _hoisted_11 = { style: {"padding":"20px 40px"} }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "md:col-12 py-2" }
const _hoisted_15 = {
  key: 0,
  class: "p-error"
}
const _hoisted_16 = { class: "md:col-12 py-2" }
const _hoisted_17 = { key: 1 }

import {
    computed, inject, reactive, ref,
} from 'vue';
import useVuelidate from '@vuelidate/core';
import {
    helpers, required,
} from '@vuelidate/validators';
import router from '@/router';
import { useToast } from 'primevue/usetoast';
import SubscriptionAPI from '@/data/api/SubscriptionAPI';
import Unsubscribe from '@/data/entity/Unsubscribe';
import Store from '@/data/Store';
import { useRoute } from 'vue-router';


export default /*@__PURE__*/_defineComponent({
  __name: 'Unsubscribe',
  setup(__props) {


const {
    auth,
} = inject<Store>('store', {});

if (auth?.authenticate) {
    console.log('Primero cierre session');
    router.push('/');
}

const recoveryId = useRoute().query?.code as string;

if (!recoveryId) {
    router.push('/login');
}

const toast = useToast();

let unsubscribe: Unsubscribe;
let subscriber: any;

const btnName = ref('Eliminar suscripción');


const isUnsubscribed = ref(false);


const formState = reactive({
    motive: '',
});

const rules = computed(() => ({
        motive: {
            required: helpers.withMessage('El motivo es obligatorio', required),
        },
    }
));

const v$ = useVuelidate(rules, formState, { $autoDirty: true });

const handleSubmit = async () => {
    await v$.value.$validate();

    if (v$.value.$invalid) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Por favor, revise los campos del formulario',
            life: 3000,
        });
    } else {
        unsubscribe = {
            subscriberId: subscriber !== null ? subscriber.id : 0,
            motive: formState.motive,
        };
        await SubscriptionAPI.InsertUnsubscription(unsubscribe)
            .then(async (response) => {
                toast.add({ severity: 'success', summary: 'Éxito', detail: response.message, life: 3000 });
                isUnsubscribed.value = true;
            })
            .catch((error) => {
                toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            });
    }
};

const mounted = async () => {
    const promises: Promise<any>[] = [];

    promises.push(SubscriptionAPI.GetSubscriptionByToken(recoveryId));

    Promise.all(promises)
        .then((values) => {
            const [pSubscriber] = values;
            subscriber = pSubscriber;
        })
        .catch(() => {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar los datos', life: 3000 });
        })
        .finally(() => {
            toast.removeAllGroups();
        });
};
mounted();

// eslint-disable-next-line no-unused-vars
const goToback = () => {
    router.go(-1);
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_router_link, { to: "/subscription" }, {
            default: _withCtx(() => _cache[1] || (_cache[1] = [
              _createElementVNode("img", {
                src: "layout/images/logo-h100.png",
                alt: "babylon-layout"
              }, null, -1)
            ])),
            _: 1
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _cache[4] || (_cache[4] = _createStaticVNode("<div class=\"col-10 md:col-5 align-items-stretch\" data-v-6ec5e972><div class=\"w-100 p-5\" data-v-6ec5e972><h1 class=\"mb-4 text-white\" data-v-6ec5e972>Proceso para eliminar suscripción.</h1><div class=\"w-100 d-flex align-items-start\" data-v-6ec5e972><div class=\"icon d-flex align-items-center justify-content-center\" data-v-6ec5e972><span class=\"fa fa-paper-plane\" data-v-6ec5e972></span></div><div class=\"text pl-4\" data-v-6ec5e972><p data-v-6ec5e972><span class=\"text-white-alpha-80\" data-v-6ec5e972>Correo:</span> <a href=\"mailto:informes@golencompliance.com\" data-v-6ec5e972>informes@golencompliance.com</a></p></div></div><div class=\"w-100 d-flex align-items-start\" data-v-6ec5e972><div class=\"icon d-flex align-items-center justify-content-center\" data-v-6ec5e972><span class=\"fa fa-globe\" data-v-6ec5e972></span></div><div class=\"text pl-4\" data-v-6ec5e972><p data-v-6ec5e972><span class=\"text-white-alpha-80\" data-v-6ec5e972>Sitio Web</span> <a href=\"https://golencompliance.com/\" target=\"_blank\" data-v-6ec5e972>golencompliance.com</a></p></div></div></div></div>", 1)),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("div", _hoisted_11, [
                    (!isUnsubscribed.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createElementVNode("div", _hoisted_13, [
                            _createElementVNode("div", _hoisted_14, [
                              _createElementVNode("div", null, [
                                _createElementVNode("label", {
                                  class: _normalizeClass(["text-white", {'p-error':_unref(v$).motive.$errors.length>0}]),
                                  for: "motive"
                                }, _cache[2] || (_cache[2] = [
                                  _createTextVNode("Motivo"),
                                  _createElementVNode("span", { class: "text-red-600" }, " *", -1)
                                ]), 2),
                                _createVNode(_component_Textarea, {
                                  id: "motive",
                                  rows: "5",
                                  autocomplete: "off",
                                  modelValue: _unref(v$).motive.$model,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).motive.$model) = $event)),
                                  modelModifiers: { trim: true },
                                  class: _normalizeClass(["w-full", {'p-invalid':_unref(v$).motive.$errors.length>0}]),
                                  placeholder: "Por favor ingrese el motivo"
                                }, null, 8, ["modelValue", "class"]),
                                ((_unref(v$).motive.$errors.length>0))
                                  ? (_openBlock(), _createElementBlock("small", _hoisted_15, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$).motive.$errors, (error) => {
                                        return (_openBlock(), _createElementBlock("p", {
                                          key: error.$uid
                                        }, _toDisplayString(error.$message), 1))
                                      }), 128))
                                    ]))
                                  : _createCommentVNode("", true)
                              ])
                            ])
                          ]),
                          _createElementVNode("div", _hoisted_16, [
                            _createVNode(_component_Button, {
                              style: {"background-color":"#BF5305","border-color":"#BF5305"},
                              onClick: handleSubmit,
                              disabled: _unref(v$).$invalid
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(btnName.value), 1)
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ])
                        ]))
                      : _createCommentVNode("", true),
                    (isUnsubscribed.value)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_17, _cache[3] || (_cache[3] = [
                          _createElementVNode("p", { style: {"color":"#FFFFFF","font-family":"Helvetica,Sans-serif","font-weight":"bold","font-size":"20px"} }, " ¡¡¡Se ha eliminado suscripción de forma exitosa!!! ", -1)
                        ])))
                      : _createCommentVNode("", true)
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})