import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createStaticVNode as _createStaticVNode } from "vue"

const _hoisted_1 = { class: "wizard-body-subscription" }
const _hoisted_2 = { class: "wizard-wrapper" }
const _hoisted_3 = { class: "wizard-header" }
const _hoisted_4 = { class: "wizard-logo" }
const _hoisted_5 = { class: "wizard-content" }
const _hoisted_6 = { class: "wizard-card" }
const _hoisted_7 = { class: "wizard-card-content" }
const _hoisted_8 = { class: "wizard-forms-wrapper grid grid-nogutter align-content-center align-items-center justify-content-center" }
const _hoisted_9 = { class: "col-10 md:col-5" }
const _hoisted_10 = { style: {"background-color":"#0B2142","border-radius":"30px"} }
const _hoisted_11 = { style: {"padding":"20px 40px"} }
const _hoisted_12 = { key: 0 }
const _hoisted_13 = { class: "" }
const _hoisted_14 = { class: "md:col-12 py-2" }
const _hoisted_15 = {
  key: 0,
  class: "p-error"
}
const _hoisted_16 = { class: "md:col-12 py-2" }
const _hoisted_17 = {
  key: 0,
  class: "p-error"
}
const _hoisted_18 = { class: "md:col-12 py-2" }
const _hoisted_19 = {
  key: 0,
  class: "p-error"
}
const _hoisted_20 = { class: "md:col-12 py-2" }
const _hoisted_21 = { class: "field" }
const _hoisted_22 = {
  key: 0,
  class: "p-error"
}
const _hoisted_23 = { class: "md:col-12 py-2" }
const _hoisted_24 = {
  key: 0,
  class: "p-error"
}
const _hoisted_25 = { class: "md:col-12 py-2" }
const _hoisted_26 = { class: "field" }
const _hoisted_27 = {
  key: 0,
  class: "p-error"
}
const _hoisted_28 = { class: "md:col-12 pb-2" }
const _hoisted_29 = { class: "field-checkbox" }
const _hoisted_30 = {
  key: 0,
  class: "p-error"
}
const _hoisted_31 = { class: "md:col-12 py-2" }
const _hoisted_32 = { key: 1 }

import {
    computed, reactive, ref,
} from 'vue';
import useVuelidate from '@vuelidate/core';
import {
    email, helpers, minLength, required,
} from '@vuelidate/validators';
import LegalMatterAPI from '@/data/api/LegalMatterAPI';
import router from '@/router';
import { useToast } from 'primevue/usetoast';
import LegalMatter from '@/data/entity/LegalMatter';
import Subscription from '@/data/entity/Subscription';
import SubscriptionAPI from '@/data/api/SubscriptionAPI';


export default /*@__PURE__*/_defineComponent({
  __name: 'Subscription',
  setup(__props) {


const toast = useToast();

let subscription: Subscription;

const btnName = ref('Suscribirme');

const legalMatters = ref<LegalMatter[]>();
const selectAllLegalMatters = ref(false);
const isChipLegalMatters = ref('chip');
const maxSelectedLabelsLegalMatters = ref(2);
const selectionLimitLegalMatters = ref(5);
const itemsSelectedLabelLegalMatters = ref('{0} materias seleccionadas');
const labelMultiSelectLegalMatters = ref('Materias');

const isSubscribed = ref(false);

const displayTerms = ref(false);

const formState = reactive({
    nameUser: '',
    lastNameUser: '',
    emailUser: '',
    cellPhoneNumberUser: '',
    companyOrStudyCompany: '',
    selectedLegalMatters: new Array<LegalMatter>(),
    acceptTerms: false,
});

const rules = computed(() => ({
        nameUser: {
            required: helpers.withMessage('Nombres son obligatorios', required),
        },
        lastNameUser: {
            required: helpers.withMessage('Apellidos son obligatorios', required),
        },
        emailUser: {
            required: helpers.withMessage('Correo es obligatorio', required),
            email: helpers.withMessage('Correo no es válido', email),
        },
        cellPhoneNumberUser: {
            required: helpers.withMessage('Número de celular es obligatorio', required),
            minLength: helpers.withMessage('Número de celular debe tener 9 dígitos', minLength(9)),
        },
        companyOrStudyCompany: {
            required: helpers.withMessage('Empresa o centro de estudio es obligatorio', required),
        },
        selectedLegalMatters: {
            required: helpers.withMessage('Materias son obligatorias', required),
            minLength: helpers.withMessage('Debe seleccionar al menos una materia', minLength(1)),
        },
        acceptTerms: {
            computed: helpers.withMessage('Debe aceptar los términos y condiciones', (value: boolean) => value === true),
        },
    }
));

const v$ = useVuelidate(rules, formState, { $autoDirty: true });

const handleSubmit = async () => {
    await v$.value.$validate();

    if (v$.value.$invalid) {
        toast.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Por favor, revise los campos del formulario',
            life: 3000,
        });
    } else {
        subscription = {
            subscriberName: formState.nameUser,
            subscriberLastName: formState.lastNameUser,
            subscriberEmail: formState.emailUser,
            subscriberCellPhoneNumber: formState.cellPhoneNumberUser,
            subscriberCompanyOrStudyCenter: formState.companyOrStudyCompany,
            subscriberAcceptTerms: formState.acceptTerms === true ? 1 : 0,
            legalMatters: formState.selectedLegalMatters as LegalMatter[],
        };
        await SubscriptionAPI.InsertSubscription(subscription)
            .then(async (response) => {
                toast.add({ severity: 'success', summary: 'Éxito', detail: response.message, life: 3000 });
                const subscriptionTmp = new Subscription();
                subscriptionTmp.id = response.subscriptionId;
                isSubscribed.value = true;
                await SubscriptionAPI.SendEmailForVerification(subscriptionTmp)
                    .then((responseEmail) => {
                        toast.add({ severity: 'success', summary: 'Éxito', detail: responseEmail.message, life: 3000 });
                    })
                    .catch((errorEmail) => {
                        toast.add({ severity: 'error', summary: 'Error', detail: errorEmail.message, life: 3000 });
                    });
            })
            .catch((error) => {
                toast.add({ severity: 'error', summary: 'Error', detail: error.message, life: 3000 });
            });
    }
};

const mounted = async () => {
    const promises: Promise<any>[] = [];

    promises.push(LegalMatterAPI.GetLegalMatterActivesBySubscription());

    Promise.all(promises)
        .then((values) => {
            const [pLegalMatters] = values;

            legalMatters.value = pLegalMatters;
        })
        .catch(() => {
            toast.add({ severity: 'error', summary: 'Error', detail: 'Error al cargar los datos', life: 3000 });
        })
        .finally(() => {
            toast.removeAllGroups();
        });
};
mounted();

const openTerms = () => {
    displayTerms.value = true;
};

const declineTerms = () => {
    formState.acceptTerms = false;
    displayTerms.value = false;
};

const AcceptTerms = () => {
    formState.acceptTerms = true;
    displayTerms.value = false;
};

const onSelectAllChangeLegalMatters = (event: any) => {
    formState.selectedLegalMatters = event.checked ? legalMatters.value! : [];
    selectAllLegalMatters.value = event.checked;
    itemsSelectedLabelLegalMatters.value = selectAllLegalMatters.value ? 'Todas las Materias' : '{0} materias seleccionadas';
    isChipLegalMatters.value = 'comma';
    labelMultiSelectLegalMatters.value = formState.selectedLegalMatters.length > 0
        ? 'Materias' : 'Materias: Seleccione materias o se considerara todas las materias';
};

const onChangeLegalMaters = (event: any) => {
    selectAllLegalMatters.value = event.value.length === legalMatters.value!.length;
    itemsSelectedLabelLegalMatters.value = selectAllLegalMatters.value ? 'Todas las Materias' : '{0} materias seleccionadas';
    isChipLegalMatters.value = event.value.length > maxSelectedLabelsLegalMatters.value ? 'comma' : 'chip';
    labelMultiSelectLegalMatters.value = formState.selectedLegalMatters.length > 0
        ? 'Materias' : 'Materias: Seleccione materias o se considerara todas las materias';
};

// eslint-disable-next-line no-unused-vars
const goToback = () => {
    router.go(-1);
};

return (_ctx: any,_cache: any) => {
  const _component_vue_pdf_app = _resolveComponent("vue-pdf-app")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputMask = _resolveComponent("InputMask")!
  const _component_MultiSelect = _resolveComponent("MultiSelect")!
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Dialog, {
      header: "TÉRMINOS Y CONDICIONES",
      visible: displayTerms.value,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((displayTerms).value = $event)),
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '50vw'},
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "No Aceptar",
          icon: "pi pi-times",
          onClick: declineTerms,
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Aceptar Terminos",
          icon: "pi pi-check",
          onClick: AcceptTerms,
          autofocus: ""
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_vue_pdf_app, {
          style: {"height":"100vh"},
          pdf: "https://files-golex.s3.us-east-2.amazonaws.com/terms/TLL0C+Usuarios+Libres+GOLEN_v1.pdf"
        })
      ]),
      _: 1
    }, 8, ["visible"]),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_router_link, { to: "/subscription" }, {
              default: _withCtx(() => _cache[10] || (_cache[10] = [
                _createElementVNode("img", {
                  src: "layout/images/logo-h100.png",
                  alt: "babylon-layout"
                }, null, -1)
              ])),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _cache[18] || (_cache[18] = _createStaticVNode("<div class=\"col-10 md:col-5 align-items-stretch\" data-v-5e518156><div class=\"w-100 p-5\" data-v-5e518156><h1 class=\"mb-4 text-white\" data-v-5e518156>¡Suscríbete! Lleva a tu organización un paso mas adelante con las nuevas normas legales.</h1><div class=\"w-100 d-flex align-items-start\" data-v-5e518156><div class=\"icon d-flex align-items-center justify-content-center\" data-v-5e518156><span class=\"fa fa-paper-plane\" data-v-5e518156></span></div><div class=\"text pl-4\" data-v-5e518156><p data-v-5e518156><span class=\"text-white-alpha-80\" data-v-5e518156>Correo:</span> <a href=\"mailto:informes@golencompliance.com\" data-v-5e518156>informes@golencompliance.com</a></p></div></div><div class=\"w-100 d-flex align-items-start\" data-v-5e518156><div class=\"icon d-flex align-items-center justify-content-center\" data-v-5e518156><span class=\"fa fa-globe\" data-v-5e518156></span></div><div class=\"text pl-4\" data-v-5e518156><p data-v-5e518156><span class=\"text-white-alpha-80\" data-v-5e518156>Sitio Web</span> <a href=\"https://golencompliance.com/\" target=\"_blank\" data-v-5e518156>golencompliance.com</a></p></div></div></div></div>", 1)),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      (!isSubscribed.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("div", _hoisted_13, [
                              _createElementVNode("div", _hoisted_14, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("label", {
                                    class: _normalizeClass(["text-white", {'p-error':_unref(v$).nameUser.$errors.length>0}]),
                                    for: "nameUser"
                                  }, _cache[11] || (_cache[11] = [
                                    _createTextVNode("Nombres"),
                                    _createElementVNode("span", { class: "text-red-600" }, " *", -1)
                                  ]), 2),
                                  _createVNode(_component_InputText, {
                                    id: "nameUser",
                                    type: "text",
                                    modelValue: _unref(v$).nameUser.$model,
                                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).nameUser.$model) = $event)),
                                    modelModifiers: { trim: true },
                                    class: _normalizeClass(["w-full", {'p-invalid':_unref(v$).nameUser.$errors.length>0}]),
                                    autocomplete: "off",
                                    placeholder: "Ingrese nombres"
                                  }, null, 8, ["modelValue", "class"]),
                                  ((_unref(v$).nameUser.$errors.length>0))
                                    ? (_openBlock(), _createElementBlock("small", _hoisted_15, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$).nameUser.$errors, (error) => {
                                          return (_openBlock(), _createElementBlock("p", {
                                            key: error.$uid
                                          }, _toDisplayString(error.$message), 1))
                                        }), 128))
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_16, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("label", {
                                    class: _normalizeClass(["text-white", {'p-error':_unref(v$).lastNameUser.$errors.length>0}]),
                                    for: "lastNameUser"
                                  }, _cache[12] || (_cache[12] = [
                                    _createTextVNode("Apellidos"),
                                    _createElementVNode("span", { class: "text-red-600" }, " *", -1)
                                  ]), 2),
                                  _createVNode(_component_InputText, {
                                    id: "lastNameUser",
                                    type: "text",
                                    modelValue: _unref(v$).lastNameUser.$model,
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(v$).lastNameUser.$model) = $event)),
                                    modelModifiers: { trim: true },
                                    class: _normalizeClass(["w-full", {'p-invalid':_unref(v$).lastNameUser.$errors.length>0}]),
                                    autocomplete: "off",
                                    placeholder: "Ingrese apellidos"
                                  }, null, 8, ["modelValue", "class"]),
                                  ((_unref(v$).lastNameUser.$errors.length>0))
                                    ? (_openBlock(), _createElementBlock("small", _hoisted_17, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$).lastNameUser.$errors, (error) => {
                                          return (_openBlock(), _createElementBlock("p", {
                                            key: error.$uid
                                          }, _toDisplayString(error.$message), 1))
                                        }), 128))
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_18, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("label", {
                                    class: _normalizeClass(["text-white", {'p-error':_unref(v$).emailUser.$errors.length>0}]),
                                    for: "emailUser"
                                  }, _cache[13] || (_cache[13] = [
                                    _createTextVNode("Correo Electronico"),
                                    _createElementVNode("span", { class: "text-red-600" }, " *", -1)
                                  ]), 2),
                                  _createVNode(_component_InputText, {
                                    id: "emailUser",
                                    type: "text",
                                    modelValue: _unref(v$).emailUser.$model,
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(v$).emailUser.$model) = $event)),
                                    modelModifiers: { trim: true },
                                    class: _normalizeClass(["w-full", {'p-invalid':_unref(v$).emailUser.$errors.length>0}]),
                                    autocomplete: "off",
                                    placeholder: "Ingrese correo electronico"
                                  }, null, 8, ["modelValue", "class"]),
                                  ((_unref(v$).emailUser.$errors.length>0))
                                    ? (_openBlock(), _createElementBlock("small", _hoisted_19, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$).emailUser.$errors, (error) => {
                                          return (_openBlock(), _createElementBlock("p", {
                                            key: error.$uid
                                          }, _toDisplayString(error.$message), 1))
                                        }), 128))
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_20, [
                                _createElementVNode("div", _hoisted_21, [
                                  _createElementVNode("label", {
                                    class: _normalizeClass(["text-white", {'p-error':_unref(v$).cellPhoneNumberUser.$errors.length>0}]),
                                    for: "cellPhoneNumberUser"
                                  }, _cache[14] || (_cache[14] = [
                                    _createTextVNode("Numero de Celular"),
                                    _createElementVNode("span", { class: "text-red-600" }, " *", -1)
                                  ]), 2),
                                  _createVNode(_component_InputMask, {
                                    id: "cellPhoneNumberUser",
                                    mask: "999999999",
                                    modelValue: _unref(v$).cellPhoneNumberUser.$model,
                                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(v$).cellPhoneNumberUser.$model) = $event)),
                                    modelModifiers: { trim: true },
                                    class: _normalizeClass(["w-full", {'p-invalid':_unref(v$).cellPhoneNumberUser.$errors.length>0}]),
                                    autocomplete: "off",
                                    placeholder: "Ingrese numero de celular"
                                  }, null, 8, ["modelValue", "class"]),
                                  ((_unref(v$).cellPhoneNumberUser.$errors.length>0))
                                    ? (_openBlock(), _createElementBlock("small", _hoisted_22, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$).cellPhoneNumberUser.$errors, (error) => {
                                          return (_openBlock(), _createElementBlock("p", {
                                            key: error.$uid
                                          }, _toDisplayString(error.$message), 1))
                                        }), 128))
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_23, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("label", {
                                    class: _normalizeClass(["text-white", {'p-error':_unref(v$).companyOrStudyCompany.$errors.length>0}]),
                                    for: "companyOrStudyCompany"
                                  }, _cache[15] || (_cache[15] = [
                                    _createTextVNode("Empresa o Centro de estudios"),
                                    _createElementVNode("span", { class: "text-red-600" }, " *", -1)
                                  ]), 2),
                                  _createVNode(_component_InputText, {
                                    id: "companyOrStudyCompany",
                                    type: "text",
                                    modelValue: _unref(v$).companyOrStudyCompany.$model,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(v$).companyOrStudyCompany.$model) = $event)),
                                    modelModifiers: { trim: true },
                                    class: _normalizeClass(["w-full", {'p-invalid':_unref(v$).companyOrStudyCompany.$errors.length>0}]),
                                    autocomplete: "off",
                                    placeholder: "Ingrese empresa o centro de estudios"
                                  }, null, 8, ["modelValue", "class"]),
                                  ((_unref(v$).companyOrStudyCompany.$errors.length>0))
                                    ? (_openBlock(), _createElementBlock("small", _hoisted_24, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$).companyOrStudyCompany.$errors, (error) => {
                                          return (_openBlock(), _createElementBlock("p", {
                                            key: error.$uid
                                          }, _toDisplayString(error.$message), 1))
                                        }), 128))
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_25, [
                                _createElementVNode("div", _hoisted_26, [
                                  _createElementVNode("label", {
                                    class: _normalizeClass(["text-white", {'p-error':_unref(v$).selectedLegalMatters.$errors.length>0}]),
                                    for: "multiselectLegalMatters"
                                  }, _cache[16] || (_cache[16] = [
                                    _createTextVNode("Materias"),
                                    _createElementVNode("span", { class: "text-red-600" }, " *", -1)
                                  ]), 2),
                                  _createVNode(_component_MultiSelect, {
                                    id: "multiselectLegalMatters",
                                    class: _normalizeClass(["w-full", {'p-invalid':_unref(v$).selectedLegalMatters.$errors.length>0}]),
                                    modelValue: _unref(v$).selectedLegalMatters.$model,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(v$).selectedLegalMatters.$model) = $event)),
                                    options: legalMatters.value,
                                    maxSelectedLabels: maxSelectedLabelsLegalMatters.value,
                                    selectAll: selectAllLegalMatters.value,
                                    selectionLimit: selectionLimitLegalMatters.value,
                                    selectedItemsLabel: itemsSelectedLabelLegalMatters.value,
                                    onSelectallChange: _cache[7] || (_cache[7] = ($event: any) => (onSelectAllChangeLegalMatters($event))),
                                    placeholder: "Seleccione materias (maximo 5)",
                                    onChange: _cache[8] || (_cache[8] = ($event: any) => (onChangeLegalMaters($event))),
                                    dataKey: "id",
                                    optionLabel: "name",
                                    filter: true,
                                    display: isChipLegalMatters.value
                                  }, null, 8, ["modelValue", "options", "maxSelectedLabels", "selectAll", "selectionLimit", "selectedItemsLabel", "display", "class"]),
                                  ((_unref(v$).selectedLegalMatters.$errors.length>0))
                                    ? (_openBlock(), _createElementBlock("small", _hoisted_27, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$).selectedLegalMatters.$errors, (error) => {
                                          return (_openBlock(), _createElementBlock("p", {
                                            key: error.$uid
                                          }, _toDisplayString(error.$message), 1))
                                        }), 128))
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ]),
                              _createElementVNode("div", _hoisted_28, [
                                _createElementVNode("div", null, [
                                  _createElementVNode("div", _hoisted_29, [
                                    _createVNode(_component_Checkbox, {
                                      inputId: "binary",
                                      modelValue: _unref(v$).acceptTerms.$model,
                                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(v$).acceptTerms.$model) = $event)),
                                      binary: true
                                    }, null, 8, ["modelValue"]),
                                    _createElementVNode("label", {
                                      class: _normalizeClass(["text-white font-bold pl-2 cursor-pointer", {'p-error':_unref(v$).acceptTerms.$errors.length>0}]),
                                      onClick: openTerms,
                                      for: "binary"
                                    }, _toDisplayString('Acepta los Terminos y condiciones'), 2)
                                  ]),
                                  ((_unref(v$).acceptTerms.$errors.length>0))
                                    ? (_openBlock(), _createElementBlock("small", _hoisted_30, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$).acceptTerms.$errors, (error) => {
                                          return (_openBlock(), _createElementBlock("p", {
                                            key: error.$uid
                                          }, _toDisplayString(error.$message), 1))
                                        }), 128))
                                      ]))
                                    : _createCommentVNode("", true)
                                ])
                              ])
                            ]),
                            _createElementVNode("div", _hoisted_31, [
                              _createVNode(_component_Button, {
                                style: {"background-color":"#BF5305","border-color":"#BF5305"},
                                onClick: handleSubmit,
                                disabled: _unref(v$).$invalid
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(btnName.value), 1)
                                ]),
                                _: 1
                              }, 8, ["disabled"])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (isSubscribed.value)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_32, _cache[17] || (_cache[17] = [
                            _createElementVNode("p", { style: {"color":"#FFFFFF","font-family":"Helvetica,Sans-serif","font-weight":"bold","font-size":"20px"} }, " ¡¡¡Se ha suscrito de forma exitosa!!! ", -1)
                          ])))
                        : _createCommentVNode("", true)
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})