import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "login-body",
  style: {"background-color":"#0B2142"}
}
const _hoisted_2 = { class: "login-content" }
const _hoisted_3 = { class: "login-input-wrapper" }
const _hoisted_4 = { class: "login-input-wrapper" }

import { inject, ref } from 'vue';
import { useToast } from 'primevue/usetoast';
import router from '@/router';
import Store from '@/data/Store';
// import global from '@/data/global';
// import ERouteType from '@/router/ERouteType';
import UserAPI from '@/data/api/UserAPI';
import User from '@/data/entity/User';
import { useRoute } from 'vue-router';

const urlTC = 'https://files-golex.s3.us-east-2.amazonaws.com/terms/TC_Usuarios_de_Empresas.pdf';
const urlTODP = 'https://files-golex.s3.us-east-2.amazonaws.com/terms/TC_para_tratamiento_de_datos_opcionales_de_USUARIOS_de_Empresas.pdf';



export default /*@__PURE__*/_defineComponent({
  __name: 'LoginRealPlaza',
  setup(__props) {

const {
    auth,
    loggedIn,
} = inject<Store>('store', {});

if (auth?.authenticate) {
    console.log('Primero cierre session');
    router.push('/');
    // if (global.isSuperUser()) {
    //     router.push(ERouteType.LICENSE_LIST_PATH);
    // } else if (global.isLawyer()) {
    //     router.push(ERouteType.LEGAL_RULE_LIST_PATH);
    // } else if (global.isAdmin()) {
    //     router.push(ERouteType.USER_LIST_PATH);
    // } else if (global.isUser()) {
    //     router.push(ERouteType.LEGAL_RULE_LIST_BY_CRITERIA_PATH);
    // }
}

const federatedCode = useRoute().query?.code as string;

const toast = useToast();

const loadingButton = ref(false);

const displayModalTC = ref(false);
const displayModalTODP = ref(false);

const loading = ref(false);
const showForm = ref(true);

const dataInit = ref<any>();


const login = async () => {
    loadingButton.value = true;
    window.location.href = 'https://golen.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=1k6kkpqp8dqvml94lb99hveeio&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fgolen.golencompliance.com%2Frealplaza';
};

const changeAccess = async () => {
    loadingButton.value = true;
    window.location.href = 'https://golen.auth.us-east-2.amazoncognito.com/login?redirect_uri=https%3A%2F%2Fgolen.golencompliance.com%2Frealplaza&response_type=code&client_id=1k6kkpqp8dqvml94lb99hveeio';
};

const saveAcceptTerms = async () => {
    displayModalTC.value = false;

    const data = dataInit.value;

    const user = new User();
    user.id = data.id;

    await UserAPI.updateAcceptTermsByUser(user);
    displayModalTODP.value = true;

};

const declineAcceptTerms = async () => {

    displayModalTC.value = false;
    toast.add({
        severity: 'warn',
        detail: 'Debe aceptar los términos y condiciones para continuar',
        life: 3000,
        group: 'login',
    });
    if (!federatedCode) {
        router.push('/login');
    } else {
        redirectToFederatedUrl();
    }
};

const saveAcceptPersonalDataProtection = async () => {
    displayModalTODP.value = false;

    const data = dataInit.value;
    loggedIn!(data.name, data, data.email, data.token);

    const user = new User();
    user.id = data.id;
    user.acceptPersonalDataProtection = 1;

    await UserAPI.updateAcceptPersonalDataProtection(user);

    toast.add({
        severity: 'success',
        detail: 'Acepto la proteccion de datos personales y recibira notificciones',
        life: 3000,
        group: 'login',
    });
    toast.add({ severity: 'success', detail: 'Inicio de sesión autorizado', life: 3000, group: 'login' });
    setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 500);


};

const declineAcceptPersonalDataProtection = async () => {
    displayModalTODP.value = false;
    const data = dataInit.value;
    loggedIn!(data.name, data, data.email, data.token);
    toast.add({
        severity: 'warn',
        detail: 'No acepto la proteccion de datos personales y no recibira notificciones',
        life: 3000,
        group: 'login',
    });
    toast.add({ severity: 'success', detail: 'Inicio de sesión autorizado', life: 3000, group: 'login' });
    setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 500);
};

const redirectToFederatedUrl = async () => {
    window.location.href = 'https://golen.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=1k6kkpqp8dqvml94lb99hveeio&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fgolen.golencompliance.com%2Frealplaza';
};


return (_ctx: any,_cache: any) => {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_vue_pdf_app = _resolveComponent("vue-pdf-app")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Loading, {
        active: loading.value,
        color: "#eeeee4",
        "background-color": "#0B2142",
        loader: "Spinner"
      }, null, 8, ["active"]),
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "login-panel" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _cache[3] || (_cache[3] = _createElementVNode("img", {
          src: "/layout/images/rp100h.png",
          alt: "Real Plaza"
        }, null, -1)),
        _cache[4] || (_cache[4] = _createElementVNode("h1", null, [
          _createElementVNode("span", null, "Inicia sesión en"),
          _createTextVNode(" golen - Real Plaza")
        ], -1)),
        _withDirectives(_createElementVNode("div", null, [
          _cache[2] || (_cache[2] = _createElementVNode("p", null, "Bienvenido, acceda con su credencial corporativa.", -1)),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_Button, {
              class: "w-full",
              label: "Acceder ...",
              loading: loadingButton.value,
              onClick: login
            }, null, 8, ["loading"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_Button, {
              class: "w-full",
              label: "Cambiar credencial de acceso",
              loading: loadingButton.value,
              onClick: changeAccess
            }, null, 8, ["loading"])
          ])
        ], 512), [
          [_vShow, showForm.value]
        ])
      ])
    ]),
    _createVNode(_component_Toast, { group: "login" }),
    _createVNode(_component_Dialog, {
      header: "TÉRMINOS Y CONDICIONES",
      visible: displayModalTC.value,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((displayModalTC).value = $event)),
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '50vw'},
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "No Aceptar",
          icon: "pi pi-times",
          onClick: declineAcceptTerms,
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Aceptar Terminos",
          icon: "pi pi-check",
          onClick: saveAcceptTerms,
          autofocus: ""
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_vue_pdf_app, {
          id: "tc",
          style: {"height":"100vh"},
          pdf: urlTC
        })
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      header: "TRATAMIENTO OPCIONAL DE DATOS PERSONALES",
      visible: displayModalTODP.value,
      "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((displayModalTODP).value = $event)),
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '50vw'},
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "No Aceptar",
          icon: "pi pi-times",
          onClick: declineAcceptPersonalDataProtection,
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Aceptar Tratamiento de Datos",
          icon: "pi pi-check",
          onClick: saveAcceptPersonalDataProtection,
          autofocus: ""
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_vue_pdf_app, {
          id: "todp",
          style: {"height":"100vh"},
          pdf: urlTODP
        })
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})