import api from '@/helpers/AxiosConfig';
import LegalMatter from '@/data/entity/LegalMatter';
import BaseApi from '@/data/api/BaseApi';
import KeywordDto from '@/data/entity/dto/KeywordDto';
import Keyword from '@/data/entity/Keyword';
import LegalMatterDto from '@/data/entity/dto/LegalMatterDto';

const URL = 'https://9ipee2d97a.execute-api.us-east-2.amazonaws.com/dev/api/legal-matters';

class LegalMatterAPI extends BaseApi {
    constructor() {
        super();
        this.url = URL;
    }

    GetLegalMatters = async (pLicenseId: number, pUserId: number): Promise<LegalMatterDto[]> => {
        const response = await api.get<LegalMatterDto[]>(this.url, {
            params: {
                licenseId: pLicenseId,
                userId: pUserId,
            },
        });
        return (response && response.data) || null;
    };

    GetLegalMatterActives = async (pLicenseId: number, pUserId: number): Promise<LegalMatter[]> => {
        const response = await api.get<LegalMatter[]>(`${this.url}/actives`, {
            params: {
                licenseId: pLicenseId,
                userId: pUserId,
            },
        });
        return (response && response.data) || null;
    };

    GetLegalMatterActivesBySubscription = async (): Promise<LegalMatter[]> => {
        const response = await api.get<LegalMatter[]>(`${this.url}/actives-by-subscription`);
        return (response && response.data) || null;
    };

    InsertLegalMatter = async (legalMatter?: LegalMatter): Promise<any> => {
        const response = await api.post<any>(this.url, legalMatter);
        return (response && response.data) || null;
    };

    UpdateLegalMatter = async (legalMatter?: LegalMatter): Promise<any> => {
        const response = await api.put<any>(this.url, legalMatter);
        return (response && response.data) || null;
    };

    DeleteLegalMatter = async (legalMatter?: LegalMatterDto): Promise<any> => {
        const response = await api.delete<any>(this.url, { data: legalMatter });
        return (response && response.data) || null;
    };

    GetLegalMatterGroupByCountry = async (pUserId: number): Promise<LegalMatter[]> => {
        const response = await api.get<LegalMatter[]>(`${this.url}/group-by-country`, {
            params: {
                userId: pUserId,
            },
        });
        return (response && response.data) || null;
    };
}

export default new LegalMatterAPI();
