export default class UserDto {
    id?: number;

    cognitoId?: string;

    name?: string;

    lastName?: string;

    documentNumber?: string;

    password?: string;

    repeatPassword?: string;

    email?: string;

    cellPhoneNumber?: string;

    accessTypeId?: number;

    accessTypeName?: string;

    stateUserId?: number;

    stateUserName?: string;

    licenseId?: number;

    licenseTypeId?: number;

    licenseStateId?: number;

    token?: string;

    acceptPersonalDataProtection?: number;

    notificationNewLegalRule?: number;

    isNotificationNewLegalRule?: boolean;

    limitLegalMatter?: number;

    isLimitLegalMatter?: boolean;

    limitCountry?: number;

    isLimitCountry?: boolean;

    limitLicenseAssociate?: number;

    isLimitLicenseAssociate?: boolean;
}
