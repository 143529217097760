import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "layout-topbar clearfix" }
const _hoisted_2 = { class: "p-text-user-login" }
const _hoisted_3 = { class: "p-link" }
const _hoisted_4 = { class: "topbar-item-name" }
const _hoisted_5 = { class: "fadeInDown" }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "topbar-badge" }
const _hoisted_8 = { key: 2 }
const _hoisted_9 = { class: "topbar-badge" }
const _hoisted_10 = { key: 3 }
const _hoisted_11 = { class: "topbar-badge" }
const _hoisted_12 = { key: 4 }
const _hoisted_13 = { class: "topbar-badge" }
const _hoisted_14 = { key: 5 }
const _hoisted_15 = { class: "topbar-badge" }
const _hoisted_16 = { key: 6 }
const _hoisted_17 = { class: "text-xl" }
const _hoisted_18 = { class: "text-lg" }
const _hoisted_19 = { key: 7 }
const _hoisted_20 = { class: "text-xl" }
const _hoisted_21 = { class: "text-lg" }
const _hoisted_22 = {
  key: 0,
  class: "text-lg"
}
const _hoisted_23 = {
  key: 0,
  class: ""
}
const _hoisted_24 = {
  key: 1,
  class: ""
}
const _hoisted_25 = ["innerHTML"]
const _hoisted_26 = ["innerHTML"]
const _hoisted_27 = ["innerHTML"]

import {
    computed,
    inject,
    ref,
    watch,
} from 'vue';
import Store from '@/data/Store';
import router from '@/router';
import NotificationAPI from '@/data/api/NotificationUserAPI';
import { useToast } from 'primevue/usetoast';
import NotificationUser from '@/data/entity/NotificationUser';
import moment from 'moment';
import { useFunctionCallStatusStore } from '@/stores/functionCallStatusStore';
import FunctionCallStatusAPI from '@/data/api/FunctionCallStatusAPI';
import LicenseAPI from '@/data/api/LicenseAPI';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppTopbar',
  props: {
    topbarMenuActive: {
        type: Boolean,
        default: false,
    },
    activeTopbarItem: {
        type: String,
        default: null,
    },
    profileMode: {
        type: String,
        default: 'popup',
    },
    horizontal: {
        type: Boolean,
        default: false,
    },
},
  emits: ['menubutton-click', 'topbar-menubutton-click', 'topbar-item-click'],
  setup(__props, { emit: __emit }) {


const functionCallStatusStore = useFunctionCallStatusStore();

const toast = useToast();


const {
    auth,
    logOut,
} = inject<Store>('store', {});

const user = auth?.user!;
const license = auth?.license!;

const loadingTable = ref(false);
const notificationsToViewed = ref<any[]>([]);
const notificationsToViewedCount = ref(0);

const nNewLegalRuleToViewed = ref<any[]>([]);
const nNewLegalRuleToViewedCount = ref(0);

const nAvailableLegalRuleToViewed = ref<any[]>([]);
const nAvailableLegalRuleToViewedCount = ref(0);

const nLicenseToViewed = ref<any[]>([]);
const nLicenseToViewedCount = ref(0);

const nDocumentRequiredToViewed = ref<any[]>([]);
const nDocumentRequiredToViewedCount = ref(0);

const nEvaluationRevaluateToViewed = ref<any[]>([]);
const nEvaluationRevaluateToViewedCount = ref(0);

const nTmpType = ref(0);

const selectedNotification = ref();
const visibleRight = ref(false);

const visibleDialog = ref(false);
const visibleDialogSummarize = ref(false);
const visibleDialogSummarizeRequirement = ref(false)

const notification = ref<NotificationUser>(new NotificationUser());

const title = ref('');
const message = ref('');

const licenseUseAiLocked = ref(false);

const visibleProgressSpinner = ref(false);
const opProgressSpinner = ref();
const toggleProgressSpinner = (event: any) => {
    opProgressSpinner.value.toggle(event);
};

const legalRuleNumberForProgressSpinner = ref('');

const visibleProgressSpinnerRequirement = ref(false);
const opProgressSpinnerRequirement = ref();
const toggleProgressSpinnerRequirement = (event: any) => {
    opProgressSpinnerRequirement.value.toggle(event);
};

const legalRuleNumberForProgressSpinnerRequirement = ref('');
const articleNumberForProgressSpinnerRequirement = ref('');
const titleRequirement = ref('');
const messageRequirement = ref('');

const props = __props;


const emit = __emit;

const onMenuButtonClick = (event: any) => {
    emit('menubutton-click', event);
};

const onTopbarMenuButtonClick = (event: any) => {
    emit('topbar-menubutton-click', event);
};

const goDashboard = () => {
    window.location.href = '/dashboard';
};

const topbarItemsClass = computed(() => {
    return ['topbar-menu fadeInDown', {
        'topbar-menu-visible': props.topbarMenuActive,
    }];
});

const logOutLocal = () => {
    if (typeof logOut === 'function') {
        logOut!();

        if(license.id == 60){
            // Para la redirección sin dejar historial en el navegador
            router.replace('/login/realplaza').catch(err => {
                // Manejar errores, por ejemplo, rutas no encontradas o problemas de redirección
                console.error(err);
            });
        } else {
            // Uso de router.replace para evitar el retorno al historial anterior
            router.replace('/login').catch(err => {
                // Manejar errores
                console.error(err);
            });
        }
    }
};

const mounted = async () => {
    localStorage.removeItem('dt-notifications-state');
    loadingTable.value = true;
    const promises: Promise<any>[] = [];
    promises.push(LicenseAPI.GetLicenseUseAiStatus(license!.id));
    promises.push(NotificationAPI.GetNotificationViewedByUser(user!.id));

    await Promise.all(promises)
        .then((values) => {
            const [
                pLicenseUseAiStatus,
                pNotifications,
            ] = values;
            licenseUseAiLocked.value = pLicenseUseAiStatus.licenseUseAiLocked === 1;

            if (licenseUseAiLocked.value === true && pLicenseUseAiStatus.functionGroupToken !== null ) {
                getGenerateSummarizeStatus(pLicenseUseAiStatus.legalRuleId, pLicenseUseAiStatus.functionGroupToken);
            }

            if(licenseUseAiLocked.value === true && pLicenseUseAiStatus.functionToken !== null ) {
                getUpdateAllArticlesFromMasterArticleByLegalRuleId(pLicenseUseAiStatus.legalRuleId, pLicenseUseAiStatus.functionToken);
            }

            notificationsToViewed.value = pNotifications;
            notificationsToViewedCount.value = pNotifications.length;

            nNewLegalRuleToViewed.value = pNotifications.filter((x: any) => x.notificationTypeId === 7);
            nNewLegalRuleToViewedCount.value = nNewLegalRuleToViewed.value.length;

            nLicenseToViewed.value = pNotifications.filter((x: any) => x.notificationTypeId === 2
                || x.notificationTypeId === 3 || x.notificationTypeId === 4 || x.notificationTypeId === 5);
            nLicenseToViewedCount.value = nLicenseToViewed.value.length;

            nDocumentRequiredToViewed.value = pNotifications.filter((x: any) => x.notificationTypeId === 1);
            nDocumentRequiredToViewedCount.value = nDocumentRequiredToViewed.value.length;

            nEvaluationRevaluateToViewed.value = pNotifications.filter((x: any) => x.notificationTypeId === 8);
            nEvaluationRevaluateToViewedCount.value = nEvaluationRevaluateToViewed.value.length;

            nAvailableLegalRuleToViewed.value = pNotifications.filter((x: any) => x.notificationTypeId === 9);
            nAvailableLegalRuleToViewedCount.value = nAvailableLegalRuleToViewed.value.length;
        })
        .catch(() => {
            loadingTable.value = false;
            console.log('Error al obtener las notificaciones');
            toast.add({
                severity: 'error',
                summary: 'Error',
                detail: 'Error al obtener las notificaciones',
                life: 3000,
                group: 'appTopbar',
            });
        })
        .finally(() => {
            loadingTable.value = false;
        });
};

mounted();

const showNotificationSlide = (nType: number) => {
    visibleRight.value = true;
    nTmpType.value = nType;
    if (nType === 1) {
        notificationsToViewed.value = nNewLegalRuleToViewed.value;
        notificationsToViewedCount.value = nNewLegalRuleToViewedCount.value;
    } else if (nType === 2) {
        notificationsToViewed.value = nLicenseToViewed.value;
        notificationsToViewedCount.value = nLicenseToViewedCount.value;
    } else if (nType === 3) {
        notificationsToViewed.value = nDocumentRequiredToViewed.value;
        notificationsToViewedCount.value = nDocumentRequiredToViewedCount.value;
    } else if (nType === 4) {
        notificationsToViewed.value = nEvaluationRevaluateToViewed.value;
        notificationsToViewedCount.value = nEvaluationRevaluateToViewedCount.value;
    } else if (nType === 5) {
        notificationsToViewed.value = nAvailableLegalRuleToViewed.value;
        notificationsToViewedCount.value = nAvailableLegalRuleToViewedCount.value;
    }
};

const onNotificationSelect = async (event: any) => {
    title.value = event.data.notificationTypeName;
    message.value = '';

    if (event.data.notificationTypeId === 1) {
        message.value = `El documento ${event.data.documentName} esta por vencer, por favor tomar las precauciones.`;
    } else if (event.data.notificationTypeId === 2) {
        message.value = `Su licencia de plataforma esta por vencer en 60 dias, por favor tomar las precauciones.`;
    } else if (event.data.notificationTypeId === 3) {
        message.value = `Su licencia de plataforma esta por vencer en 30 dias, por favor tomar las precauciones.`;
    } else if (event.data.notificationTypeId === 4) {
        message.value = `Su licencia de plataforma esta por vencer en 15 dias, por favor tomar las precauciones.`;
    } else if (event.data.notificationTypeId === 5) {
        message.value = `Su licencia de plataforma esta por vencer en 5 dias, por favor tomar las precauciones.`;
    } else if (event.data.notificationTypeId === 7) {
        message.value = '<div class=\'text-xl\'><strong>' + event.data.issuingEntityName + '</strong></div>\n' +
            '<div class=\'text-lg\'><strong>' + event.data.legalRuleTypeName + ' N° ' + event.data.legalRuleNumber + '</strong></div>' +
            '<div class=\'text-base\'><strong>Titulo: </strong>' + event.data.legalRuleTitle + '</div>\n' +
            '<div class=\'text-base\'><strong>Fecha de Publicación: </strong>' + moment(event.data.legalRulePublicationDate).utcOffset('GMT-00:00').format('DD/MM/YYYY') + '</div>';
    } else if (event.data.notificationTypeId === 8) {
        message.value = `Se ha agregado una nueva evaluación.`;
    } else if (event.data.notificationTypeId === 9) {
        message.value = '<div class=\'text-xl\'><strong>' + event.data.issuingEntityName + '</strong></div>\n' +
            '<div class=\'text-lg\'><strong>' + event.data.legalRuleTypeName + ' N° ' + event.data.legalRuleNumber + '</strong></div>' +
            '<div class=\'text-base\'><strong>Titulo: </strong>' + event.data.legalRuleTitle + '</div>\n' +
            '<div class=\'text-base\'><strong>Fecha de Publicación: </strong>' + moment(event.data.legalRulePublicationDate).utcOffset('GMT-00:00').format('DD/MM/YYYY') + '</div>';
    } else {
        message.value = `No se ha encontrado el tipo de notificación.`;
    }
    visibleDialog.value = true;

    notification.value.id = event.data.id;

    await NotificationAPI.UpdateNotificationUserToViewed(notification.value)
        .then(() => {
            console.log('Se actualizo correctamente');
        }).catch(() => {
            console.log('Error al actualizar');
        }).finally(() => {
            console.log('Finalizo');
        });

    await mounted();

    await showNotificationSlide(nTmpType.value);

};

const onNotificationClosed = async () => {
    await NotificationAPI.UpdateNotificationUserToClosed(notification.value)
        .then(() => {
            console.log('Se actualizo correctamente');
        }).catch(() => {
            console.log('Error al actualizar');
        }).finally(() => {
            console.log('Finalizo');
        });

    visibleDialog.value = false;

    await mounted();

    await showNotificationSlide(nTmpType.value);
};

const rowClass = (data: any) => {
    if (data.viewed == 1) {
        return 'row-viewed';
    } else {
        return null;
    }
};

watch(
    [
        () => functionCallStatusStore.functionToken,
        () => functionCallStatusStore.functionGroupToken,
    ],
    ([
         newFunctionToken,
         newFunctionGroupToken,
     ]) => {
        if (newFunctionGroupToken !== null && functionCallStatusStore.legalRuleId !== null) {
            getGenerateSummarizeStatus(functionCallStatusStore.legalRuleId, newFunctionGroupToken);
        }

        if (newFunctionToken !== null && functionCallStatusStore.legalRuleRequirementId !== null) {
            getUpdateAllArticlesFromMasterArticleByLegalRuleId(functionCallStatusStore.legalRuleRequirementId, newFunctionToken);
        }
    },
);

const getGenerateSummarizeStatus = async (legalRuleId: number, functionGroupToken: string) => {
    visibleProgressSpinner.value = true;
    message.value = '';
    let keepChecking = true;

    while (keepChecking) {

        await FunctionCallStatusAPI.GetFunctionCallStatusForGroup(functionGroupToken)
            .then((response: any) => {
                const responseStatus = response.responseStatus;
                let responseMessage = response.message;
                let functionTypeId = response.functionTypeId;
                let legalRuleNumber = response.legalRuleNumber;
                let articlesNumberError = '';
                legalRuleNumberForProgressSpinner.value = legalRuleNumber;

                if (functionTypeId === 8) {
                    title.value = 'Generacion de Resumenes';
                } else if (functionTypeId === 9) {
                    title.value = 'Generacion de Recomendaciones';
                } else if (functionTypeId === 15) {
                    title.value = 'Generacion de Evidencias';
                }
                message.value = '<div class=\'text-xl\'><strong>' + responseMessage + '</strong></div>\n' +
                    '<div class=\'text-base\'><strong>Numero de Norma: </strong>' + legalRuleNumber + '</div>\n';

                if (responseStatus === 1) {
                    visibleDialogSummarize.value = true;
                    functionCallStatusStore.setReloadArticleList(1);
                    visibleProgressSpinner.value = false;
                    keepChecking = false;
                } else if (responseStatus === 2) {
                    visibleDialogSummarize.value = false;
                    functionCallStatusStore.setReloadArticleList(1);
                    visibleProgressSpinner.value = false;
                    keepChecking = false;
                } else if (responseStatus === 3) {
                    articlesNumberError = response.articlesNumberError;
                    message.value = message.value + '<div class=\'text-base\'><strong>Numero de los articulos con error: </strong>' + articlesNumberError + '</div>\n';
                    visibleDialogSummarize.value = true;
                    functionCallStatusStore.setReloadArticleList(1);
                    visibleProgressSpinner.value = false;
                    keepChecking = false;
                } else if (responseStatus === 4) {
                    visibleDialogSummarize.value = false;
                    visibleProgressSpinner.value = true;
                    keepChecking = true;
                }
                // console.log(response);
            })
            .catch((error) => {
                message.value = '<div class=\'text-xl\'><strong>' + error + '</strong></div>\n';
                visibleDialogSummarize.value = true;
                console.log(error);
                visibleProgressSpinner.value = false;
                keepChecking = false;
            })
            .finally(() => {
                functionCallStatusStore.functionGroupToken = null;
                functionCallStatusStore.legalRuleId = null;
            });

        if (keepChecking) {
            await new Promise(resolve => setTimeout(resolve, 30000)); // Esperar 30 segundos
        }
    }
};

const getUpdateAllArticlesFromMasterArticleByLegalRuleId = async (legalRuleId: number, functionToken: string) => {
    visibleProgressSpinnerRequirement.value = true;
    messageRequirement.value = '';
    let keepChecking = true;

    let functionStateId = 0;

    while (keepChecking) {

        await FunctionCallStatusAPI.GetFunctionCallStatus(functionToken)
            .then((response: any) => {
                let legalRuleNumber = response.legalRuleNumber;
                let articleNumber = response.articleNumber
                legalRuleNumberForProgressSpinnerRequirement.value = legalRuleNumber;
                articleNumberForProgressSpinnerRequirement.value = articleNumber;
                titleRequirement.value = 'Actualizacion de requisitos de articulos';

                functionStateId = response.functionStateId;
                const functionResponseStateId = response.functionResponseStateId;
                if (functionStateId === 3) {
                    if (functionResponseStateId === 2) {
                      messageRequirement.value = '<div class=\'text-xl\'><strong>Se finalizo la actualizacion de los articulos</strong></div>\n' +
                          '<div class=\'text-base\'><strong>Numero de Norma: </strong>' + legalRuleNumber + '</div>\n' +
                          '<div v-if=\'articleNumber !== ""\' class=\'text-base\'><strong>Numero de Articulo: </strong>' + articleNumber + '</div>\n';
                    } else if (functionResponseStateId === 3) {
                      messageRequirement.value = '<div class=\'text-xl\'><strong>Error en actualizacion de los articulos</strong></div>\n' +
                          '<div class=\'text-base\'><strong>Numero de Norma: </strong>' + legalRuleNumber + '</div>\n' +
                          '<div v-if=\'articleNumber !== ""\' class=\'text-base\'><strong>Numero de Articulo: </strong>' + articleNumber + '</div>\n';
                    }
                    visibleDialogSummarizeRequirement.value = true;
                    visibleProgressSpinnerRequirement.value = false;
                    functionCallStatusStore.setReloadArticleList(1);
                    keepChecking = false;
                }
                // console.log(response);
            })
            .catch((error) => {
                messageRequirement.value = '<div class=\'text-xl\'><strong>' + error + '</strong></div>\n';
                visibleDialogSummarizeRequirement.value = true;
                console.log(error);
                visibleProgressSpinnerRequirement.value = false;
                keepChecking = false;
            })
            .finally(() => {
                functionCallStatusStore.functionToken = null;
                functionCallStatusStore.legalRuleRequirementId = null;
            });

        if (keepChecking) {
            await new Promise(resolve => setTimeout(resolve, 30000)); // Esperar 30 segundos
        }
    }
};



return (_ctx: any,_cache: any) => {
  const _component_ProgressSpinner = _resolveComponent("ProgressSpinner")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Sidebar = _resolveComponent("Sidebar")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        class: "layout-topbar-logo p-link",
        onClick: goDashboard
      }, _cache[15] || (_cache[15] = [
        _createElementVNode("img", {
          id: "layout-topbar-logo",
          alt: "babylon-layout",
          src: "layout/images/logo-h100w.png"
        }, null, -1)
      ])),
      _createElementVNode("button", {
        class: "layout-menu-button p-link",
        onClick: onMenuButtonClick
      }, _cache[16] || (_cache[16] = [
        _createElementVNode("i", { class: "pi pi-bars" }, null, -1)
      ])),
      _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(user).accessTypeName + ': ' + _unref(user).name + ' ' + _unref(user).lastName), 1),
      _createElementVNode("button", {
        id: "topbar-menu-button",
        class: "p-link",
        onClick: onTopbarMenuButtonClick
      }, _cache[17] || (_cache[17] = [
        _createElementVNode("i", { class: "pi pi-ellipsis-v" }, null, -1)
      ])),
      _createElementVNode("ul", {
        class: _normalizeClass(topbarItemsClass.value)
      }, [
        (__props.profileMode === 'popup' || __props.horizontal)
          ? (_openBlock(), _createElementBlock("li", {
              key: 0,
              class: _normalizeClass(['user-profile', {'active-topmenuitem': __props.activeTopbarItem === 'profile'}]),
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('topbar-item-click',{originalEvent:$event,item:'profile'})))
            }, [
              _createElementVNode("button", _hoisted_3, [
                _cache[18] || (_cache[18] = _createElementVNode("img", {
                  alt: "babylon-layout",
                  src: "layout/images/avatar-gray.png",
                  style: {"border-radius":"30px"}
                }, null, -1)),
                _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(user).name), 1)
              ]),
              _createVNode(_Transition, { name: "layout-submenu-container" }, {
                default: _withCtx(() => [
                  _withDirectives(_createElementVNode("ul", _hoisted_5, [
                    _createElementVNode("li", { role: "menuitem" }, [
                      _createElementVNode("button", {
                        class: "p-link",
                        onClick: logOutLocal
                      }, _cache[19] || (_cache[19] = [
                        _createElementVNode("i", { class: "pi pi-sign-out" }, null, -1),
                        _createElementVNode("span", null, "Cerrar Sesión", -1)
                      ]))
                    ])
                  ], 512), [
                    [_vShow, __props.activeTopbarItem === 'profile']
                  ])
                ]),
                _: 1
              })
            ], 2))
          : _createCommentVNode("", true),
        (nEvaluationRevaluateToViewedCount.value > 0)
          ? (_openBlock(), _createElementBlock("li", _hoisted_6, [
              _createElementVNode("button", {
                class: "p-link",
                onClick: _cache[1] || (_cache[1] = ($event: any) => (showNotificationSlide(4)))
              }, [
                _cache[20] || (_cache[20] = _createElementVNode("i", { class: "topbar-icon pi pi-download" }, null, -1)),
                _cache[21] || (_cache[21] = _createElementVNode("span", { class: "topbar-item-name" }, "Revaluacion", -1)),
                _createElementVNode("span", _hoisted_7, _toDisplayString(nEvaluationRevaluateToViewedCount.value), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (nDocumentRequiredToViewedCount.value > 0)
          ? (_openBlock(), _createElementBlock("li", _hoisted_8, [
              _createElementVNode("button", {
                class: "p-link",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (showNotificationSlide(3)))
              }, [
                _cache[22] || (_cache[22] = _createElementVNode("i", { class: "topbar-icon pi pi-clone" }, null, -1)),
                _cache[23] || (_cache[23] = _createElementVNode("span", { class: "topbar-item-name" }, "Documento Requerido", -1)),
                _createElementVNode("span", _hoisted_9, _toDisplayString(nDocumentRequiredToViewedCount.value), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (nLicenseToViewedCount.value > 0)
          ? (_openBlock(), _createElementBlock("li", _hoisted_10, [
              _createElementVNode("button", {
                class: "p-link",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (showNotificationSlide(2)))
              }, [
                _cache[24] || (_cache[24] = _createElementVNode("i", { class: "topbar-icon pi pi-shield" }, null, -1)),
                _cache[25] || (_cache[25] = _createElementVNode("span", { class: "topbar-item-name" }, "Licencia", -1)),
                _createElementVNode("span", _hoisted_11, _toDisplayString(nLicenseToViewedCount.value), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (nNewLegalRuleToViewedCount.value > 0)
          ? (_openBlock(), _createElementBlock("li", _hoisted_12, [
              _createElementVNode("button", {
                class: "p-link",
                onClick: _cache[4] || (_cache[4] = ($event: any) => (showNotificationSlide(1)))
              }, [
                _cache[26] || (_cache[26] = _createElementVNode("i", { class: "topbar-icon pi pi-book" }, null, -1)),
                _cache[27] || (_cache[27] = _createElementVNode("span", { class: "topbar-item-name" }, "Nueva Norma", -1)),
                _createElementVNode("span", _hoisted_13, _toDisplayString(nNewLegalRuleToViewedCount.value), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (nAvailableLegalRuleToViewedCount.value > 0)
          ? (_openBlock(), _createElementBlock("li", _hoisted_14, [
              _createElementVNode("button", {
                class: "p-link",
                onClick: _cache[5] || (_cache[5] = ($event: any) => (showNotificationSlide(5)))
              }, [
                _cache[28] || (_cache[28] = _createElementVNode("i", { class: "topbar-icon pi pi-check-circle" }, null, -1)),
                _cache[29] || (_cache[29] = _createElementVNode("span", { class: "topbar-item-name" }, "Nueva Norma disponible", -1)),
                _createElementVNode("span", _hoisted_15, _toDisplayString(nAvailableLegalRuleToViewedCount.value), 1)
              ])
            ]))
          : _createCommentVNode("", true),
        (visibleProgressSpinner.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_16, [
              _createVNode(_component_ProgressSpinner, {
                style: {"width":"40px","height":"40px","padding-top":"20px","padding-left":"10px"},
                class: "cursor-pointer",
                strokeWidth: "10",
                fill: "var(--surface-ground)",
                animationDuration: ".5s",
                onClick: _cache[6] || (_cache[6] = ($event: any) => (toggleProgressSpinner($event))),
                "aria-label": "Custom ProgressSpinner"
              }),
              _createVNode(_component_OverlayPanel, {
                ref_key: "opProgressSpinner",
                ref: opProgressSpinner
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_17, _toDisplayString(title.value), 1),
                  _createElementVNode("div", _hoisted_18, [
                    _cache[30] || (_cache[30] = _createTextVNode(" Numero de Norma: ")),
                    _createElementVNode("strong", null, _toDisplayString(legalRuleNumberForProgressSpinner.value), 1)
                  ])
                ]),
                _: 1
              }, 512)
            ]))
          : _createCommentVNode("", true),
        (visibleProgressSpinnerRequirement.value)
          ? (_openBlock(), _createElementBlock("li", _hoisted_19, [
              _createVNode(_component_ProgressSpinner, {
                style: {"width":"40px","height":"40px","padding-top":"20px","padding-left":"10px"},
                class: "cursor-pointer",
                strokeWidth: "10",
                fill: "var(--surface-ground)",
                animationDuration: ".5s",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (toggleProgressSpinnerRequirement($event))),
                "aria-label": "Custom ProgressSpinner"
              }),
              _createVNode(_component_OverlayPanel, {
                ref_key: "opProgressSpinnerRequirement",
                ref: opProgressSpinnerRequirement
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_20, _toDisplayString(titleRequirement.value), 1),
                  _createElementVNode("div", _hoisted_21, [
                    _cache[31] || (_cache[31] = _createTextVNode(" Numero de Norma: ")),
                    _createElementVNode("strong", null, _toDisplayString(legalRuleNumberForProgressSpinnerRequirement.value), 1)
                  ]),
                  (articleNumberForProgressSpinnerRequirement.value !== "")
                    ? (_openBlock(), _createElementBlock("div", _hoisted_22, [
                        _cache[32] || (_cache[32] = _createTextVNode(" Numero de Articulo: ")),
                        _createElementVNode("strong", null, _toDisplayString(articleNumberForProgressSpinnerRequirement.value), 1)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 512)
            ]))
          : _createCommentVNode("", true)
      ], 2),
      _createVNode(_component_Sidebar, {
        visible: visibleRight.value,
        "onUpdate:visible": _cache[9] || (_cache[9] = ($event: any) => ((visibleRight).value = $event)),
        baseZIndex: 10000,
        position: "right"
      }, {
        default: _withCtx(() => [
          _createElementVNode("h3", null, "Notificaciones (" + _toDisplayString(notificationsToViewed.value.length) + ")", 1),
          _createVNode(_component_DataTable, {
            loading: loadingTable.value,
            value: notificationsToViewed.value,
            selection: selectedNotification.value,
            "onUpdate:selection": _cache[8] || (_cache[8] = ($event: any) => ((selectedNotification).value = $event)),
            selectionMode: "single",
            dataKey: "id",
            sortField: "id",
            sortOrder: -1,
            stripedRows: "",
            rowClass: rowClass,
            onRowSelect: onNotificationSelect,
            responsiveLayout: "scroll"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Column, {
                field: "notificationTypeName",
                style: {"width":"100%"}
              }, {
                body: _withCtx((row) => [
                  (row.data.notificationTypeId === 1)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_23, _toDisplayString(row.data.notificationTypeName + ' - ' + row.data.documentName), 1))
                    : (_openBlock(), _createElementBlock("span", _hoisted_24, _toDisplayString(row.data.notificationTypeName), 1))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["loading", "value", "selection"])
        ]),
        _: 1
      }, 8, ["visible"]),
      _createVNode(_component_Toast, {
        position: "top-center",
        group: "appTopbar"
      })
    ]),
    _createVNode(_component_Dialog, {
      visible: visibleDialog.value,
      "onUpdate:visible": _cache[10] || (_cache[10] = ($event: any) => ((visibleDialog).value = $event)),
      modal: "",
      header: title.value,
      position: "topright",
      style: { width: '30%' }
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Eliminar Notificación",
          icon: "pi pi-check",
          onClick: onNotificationClosed,
          autofocus: ""
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", { innerHTML: message.value }, null, 8, _hoisted_25)
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_component_Dialog, {
      visible: visibleDialogSummarize.value,
      "onUpdate:visible": _cache[12] || (_cache[12] = ($event: any) => ((visibleDialogSummarize).value = $event)),
      header: title.value,
      position: "top",
      style: { width: '30%' }
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cerrar",
          icon: "pi pi-check",
          onClick: _cache[11] || (_cache[11] = ($event: any) => (visibleDialogSummarize.value = false)),
          autofocus: ""
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", { innerHTML: message.value }, null, 8, _hoisted_26)
      ]),
      _: 1
    }, 8, ["visible", "header"]),
    _createVNode(_component_Dialog, {
      visible: visibleDialogSummarizeRequirement.value,
      "onUpdate:visible": _cache[14] || (_cache[14] = ($event: any) => ((visibleDialogSummarizeRequirement).value = $event)),
      header: titleRequirement.value,
      position: "top",
      style: { width: '30%' }
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Cerrar",
          icon: "pi pi-check",
          onClick: _cache[13] || (_cache[13] = ($event: any) => (visibleDialogSummarizeRequirement.value = false)),
          autofocus: ""
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("p", { innerHTML: messageRequirement.value }, null, 8, _hoisted_27)
      ]),
      _: 1
    }, 8, ["visible", "header"])
  ], 64))
}
}

})