import api from '@/helpers/AxiosConfig';
import BaseApi from '@/data/api/BaseApi';
import S3 from 'aws-sdk/clients/s3';
import UserAPI from '@/data/api/UserAPI';

const URL = 'https://ru7widbnm0.execute-api.us-east-2.amazonaws.com/dev/api/support';

const BUCKET_NAME = process.env.VUE_APP_AWS_BUCKET_NAME;

class FunctionCallStatusAPI extends BaseApi {
    constructor() {
        super();
        this.url = URL;
    }

    GetFunctionCallStatus = async (PFunctionToken: string): Promise<any[]> => {
        const response = await api.get<any[]>(`${this.url}/function-call-status`, {
            params: {
                functionToken: PFunctionToken,
            },
        });
        return (response && response.data) || null;
    };

    GetFunctionCallStatusForGroup = async (PFunctionGroupToken: string): Promise<any[]> => {
        const response = await api.get<any[]>(`${this.url}/function-call-status-for-group`, {
            params: {
                functionGroupToken: PFunctionGroupToken,
            },
        });
        return (response && response.data) || null;
    };

    GetFunctionCallStatusForFile = async (PFunctionToken: string): Promise<any[]> => {
        const response = await api.get<any[]>(`${this.url}/function-call-status`, {
            params: {
                functionToken: PFunctionToken,
            },
        });
        const dataResponse = (response && response.data) || null;
        let dataResponseFinal: any = {};

        const dataTokensTemp : any = await UserAPI.GetTemporaryCredentials();
        const s3 = new S3({
            region: process.env.VUE_APP_AWS_REGION,
            credentials: {
                accessKeyId: dataTokensTemp.accessKeyId,
                secretAccessKey: dataTokensTemp.secretAccessKey,
                sessionToken: dataTokensTemp.sessionToken,
            },
        });

        if (response && response.data) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const functionStateId = response.data.functionStateId;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const functionResponseStateId = response.data.functionResponseStateId;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const functionResponse = JSON.parse(response.data.functionResponse);

            if (functionStateId === 3) {
                if (functionResponseStateId === 2) {
                    const { Key, Bucket } = functionResponse;

                    const params: any = {
                        Bucket,
                        Key,
                    };

                    await s3.getObject(params).promise().then((data: any) => {
                        const dataFile = data.Body.toString();
                        // console.log('dataFile', dataFile);
                        const tmpData = [...JSON.parse(dataFile)];
                        dataResponseFinal = {
                            functionStateId: functionStateId,
                            functionResponseStateId: functionResponseStateId,
                            data: tmpData,
                        };
                        // console.log('dataResponse', dataResponse);
                    }).catch((err) => {
                        console.log('err', err);
                    });

                    try {
                        await s3.headObject(params).promise();
                        // console.log('File Found in S3');
                        try {
                            await s3.deleteObject(params).promise();
                            // console.log('file deleted Successfully');
                        } catch (err) {
                            console.log(`ERROR in file Deleting : ${JSON.stringify(err)}`);
                        }
                    } catch (err: any) {
                        console.log(`File not Found ERROR : ${err.code}`);
                    }
                } else if (functionResponseStateId === 3) {
                    dataResponseFinal = {
                        functionStateId: functionStateId,
                        functionResponseStateId: functionResponseStateId,
                    };
                }
            }
        }
        // console.log('dataResponseEnviadooo', dataResponse);
        return dataResponseFinal;
    };

}

export default new FunctionCallStatusAPI;
