<template>
    <div class='login-body'
         style='background-color: #0B2142'>
        <Loading
            :active='loading'
            color='#eeeee4'
            background-color='#0B2142'
            loader='Spinner' />
        <div class='login-panel'></div>
        <div class='login-content'>
            <img src='layout/images/logo-h100.png' alt='Golen' />
            <h1><span>Inicia sesión en</span> golen - peru</h1>
            <div v-show='showForm'>
                <p>Bienvenido, utilice el formulario para iniciar sesión.</p>
                <div class='login-input-wrapper'
                     :class="{'p-error':v$['email'].$errors.length>0}">
                    <InputText
                        v-model='v$["email"].$model'
                        placeholder='Correo electronico' />
                    <i class='pi pi-user'></i>
                    <small
                        v-if='(v$["email"].$errors.length>0)'
                        class='p-error'>
                    <span
                        v-for='error of v$["email"].$errors'
                        :key='error.$uid'>{{ error.$message }}</span>
                    </small>
                </div>
                <div class='login-input-wrapper'>
                    <InputText
                        type='password'
                        v-model='v$["password"].$model'
                        placeholder='Password' />
                    <i class='pi pi-lock'></i>
                </div>
                <div class='login-input-wrapper text-right'>
                    <a
                        @click='passwordRecovery'
                        class='font-medium text-white hover:text-gray-400 cursor-pointer'>
                        Olvidaste tu contraseña?
                    </a>
                </div>
                <div class='login-input-wrapper'>
                    <Button
                        class='w-full'
                        label='Ingresar'
                        :disabled='btnAcceptDisable'
                        :loading='loadingButton'
                        @click='login'
                    />
                </div>
            </div>
        </div>
    </div>
    <Toast group='login' />
    <Dialog
        header='TÉRMINOS Y CONDICIONES'
        v-model:visible='displayModalTC'
        :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '50vw'}"
        :modal='true'>
        <vue-pdf-app
            id='tc'
            style='height: 100vh;'
            :pdf='urlTC' />
        <template #footer>
            <Button
                label='No Aceptar'
                icon='pi pi-times'
                @click='declineAcceptTerms'
                class='p-button-text' />
            <Button
                label='Aceptar Terminos'
                icon='pi pi-check'
                @click='saveAcceptTerms'
                autofocus />
        </template>
    </Dialog>
    <Dialog
        header='TRATAMIENTO OPCIONAL DE DATOS PERSONALES'
        v-model:visible='displayModalTODP'
        :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '50vw'}"
        :modal='true'>
        <vue-pdf-app
            id='todp'
            style='height: 100vh;'
            :pdf='urlTODP' />
        <template #footer>
            <Button
                label='No Aceptar'
                icon='pi pi-times'
                @click='declineAcceptPersonalDataProtection'
                class='p-button-text' />
            <Button
                label='Aceptar Tratamiento de Datos'
                icon='pi pi-check'
                @click='saveAcceptPersonalDataProtection'
                autofocus />
        </template>
    </Dialog>
    <Dialog
        header='Licencias Asociadas Disponibles'
        v-model:visible='displayModalLicense'
        :breakpoints="{'960px': '75vw', '640px': '90vw'}"
        :style="{width: '30vw'}"
        :closable='false'
        :modal='true'>
        <div class='flex flex-column gap-4'>
            <div v-for='licenseAssociate in licenseAssociatesByLicense'
                 :key='licenseAssociate.id'
                 class='flex items-center'>
                <RadioButton
                    v-model='selectedLicense'
                    :inputId='licenseAssociate.id'
                    :value='licenseAssociate.id' />
                <label :for='licenseAssociate.id'
                       class='ml-2'>{{ licenseAssociate.identificationFiscal + ' - ' + licenseAssociate.businessName
                    }}</label>
            </div>
        </div>
        <template #footer>
            <Button
                label='Licencia por defecto'
                icon='pi pi-times'
                @click='selectedLicenseDefault'
                class='p-button-text' />
            <Button
                label='Licencia Seleccionada'
                icon='pi pi-check'
                :disabled='!selectedLicense'
                @click='selectedLicenseAssociate'
                autofocus />
        </template>
    </Dialog>
</template>

<script setup lang='ts'>
import { computed, inject, reactive, ref } from 'vue';
import { email, helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import router from '@/router';
import Store from '@/data/Store';
// import global from '@/data/global';
// import ERouteType from '@/router/ERouteType';
import UserAPI from '@/data/api/UserAPI';
import User from '@/data/entity/User';
import UserDto from '@/data/entity/dto/UserDto';
import { useRoute } from 'vue-router';
import LicenseAPI from '@/data/api/LicenseAPI';

const {
    auth,
    loggedIn,
} = inject<Store>('store', {});

if (auth?.authenticate) {
    console.log('Primero cierre session');
    router.push('/');
    // if (global.isSuperUser()) {
    //     router.push(ERouteType.LICENSE_LIST_PATH);
    // } else if (global.isLawyer()) {
    //     router.push(ERouteType.LEGAL_RULE_LIST_PATH);
    // } else if (global.isAdmin()) {
    //     router.push(ERouteType.USER_LIST_PATH);
    // } else if (global.isUser()) {
    //     router.push(ERouteType.LEGAL_RULE_LIST_BY_CRITERIA_PATH);
    // } else if (global.isLawyerUser()) {
    //     router.push(ERouteType.LEGAL_RULE_LIST_BY_CRITERIA_PATH);
    // }
}

const federatedCode = useRoute().query?.code as string;
const corp = useRoute().query?.corp as string;

const toast = useToast();

const loadingButton = ref(false);

const displayModalTC = ref(false);
const displayModalTODP = ref(false);
const displayModalLicense = ref(false);

const loading = ref(false);
const showForm = ref(true);

const urlTC = 'https://files-golex.s3.us-east-2.amazonaws.com/terms/TC_Usuarios_de_Empresas.pdf';
const urlTODP = 'https://files-golex.s3.us-east-2.amazonaws.com/terms/TC_para_tratamiento_de_datos_opcionales_de_USUARIOS_de_Empresas.pdf';

const formState = reactive({
    email: '',
    password: '',
});

const acceptTerms = ref(false);
const dataInit = ref<any>();
// const licenseStateId = ref(0);
const isBlocked = ref(false);

const rules = computed(() => ({
    email: {
        email: helpers.withMessage('Email no es válido', email),
    },
    password: {
        required: helpers.withMessage(`El password es obligatorio`, required),
    },
}));

const licenseAssociatesByLicense = ref<any[]>([]);
const selectedLicense = ref<any>(null);

const v$ = useVuelidate(rules, formState, { $autoDirty: true });

const btnAcceptDisable = computed(() => {
    return v$.value.$invalid;

});

const mounted = async () => {

    if (!federatedCode) {
        router.push('/login');
    } else {
        showForm.value = false;
        loading.value = true;
        // console.log('federatedCode', federatedCode);
        // console.log('corp', corp);
        const newData: any = {};
        newData['code'] = federatedCode;
        newData['corp'] = corp;
        // console.log('newData', newData);
        await UserAPI.GetLoginFederatedUser(newData)
            .then((data: any) => {
                // console.log('data', data);
                isBlocked.value = data.isBlocked;
                loadingButton.value = false;
                if (isBlocked.value) {
                    toast.add({
                        severity: 'error',
                        detail: data.message,
                        life: 3000,
                        group: 'login',
                    });
                    return;
                }

                // licenseStateId.value = data.licenseStateId;
                //
                // if (licenseStateId.value === 2) {
                //     toast.add({
                //         severity: 'error',
                //         detail: 'Su licencia se encuentra vencida',
                //         life: 3000,
                //         group: 'login',
                //     });
                //     return;
                // }
                // if (licenseStateId.value === 3) {
                //     toast.add({
                //         severity: 'error',
                //         detail: 'Su licencia se encuentra suspendida',
                //         life: 3000,
                //         group: 'login',
                //     });
                //     return;
                // }

                dataInit.value = data;
                // aceptacion de terminos y condiciones
                acceptTerms.value = data.acceptTerms === 1;
                if (acceptTerms.value === false) {
                    displayModalTC.value = true;
                } else {
                    toast.add({
                        severity: 'success',
                        detail: 'Inicio de sesión autorizado',
                        life: 3000,
                        group: 'login',
                    });
                    loggedIn!(data.name, data, data.email, data.token);

                    setTimeout(() => {
                        // eslint-disable-next-line no-restricted-globals
                        location.reload();
                    }, 500);
                }
            })
            .catch((error) => {
                console.log('error', error);
                toast.add({
                    severity: 'error',
                    detail: 'Los datos ingresados no son correctos',
                    life: 3000,
                    group: 'login',
                });
            });
        loading.value = false;
    }

};

mounted();

const login = async () => {
    loadingButton.value = true;
    await v$.value.$validate();

    if (v$.value.$invalid) {
        // console.log('v$.value', v$.value);
        loadingButton.value = false;
        toast.add({ severity: 'warn', detail: 'Los datos ingresados no son correctos', life: 3000, group: 'login' });
    } else {
        const user = new UserDto();
        user.email = formState.email;
        user.password = formState.password;
        UserAPI.GetLoginUserCognito(user)
            .then(async (data: any) => {
                isBlocked.value = data.isBlocked;
                loadingButton.value = false;
                if (isBlocked.value) {
                    toast.add({
                        severity: 'error',
                        detail: data.message,
                        life: 3000,
                        group: 'login',
                    });
                    return;
                }
                // licenseStateId.value = data.licenseStateId;
                //
                // if (licenseStateId.value === 2) {
                //     toast.add({
                //         severity: 'error',
                //         detail: 'Su licencia se encuentra vencida',
                //         life: 3000,
                //         group: 'login',
                //     });
                //     return;
                // }
                // if (licenseStateId.value === 3) {
                //     toast.add({
                //         severity: 'error',
                //         detail: 'Su licencia se encuentra suspendida',
                //         life: 3000,
                //         group: 'login',
                //     });
                //     return;
                // }

                dataInit.value = data;
                // aceptacion de terminos y condiciones
                acceptTerms.value = data.acceptTerms === 1;
                if (acceptTerms.value === false) {
                    displayModalTC.value = true;
                } else {
                    toast.add({
                        severity: 'success',
                        detail: 'Inicio de sesión autorizado',
                        life: 3000,
                        group: 'login',
                    });

                    if (data.accessTypeId === 6 || data.accessTypeId === 7) {
                        licenseAssociatesByLicense.value = await LicenseAPI.GetLicenseAssociatesByLicenseAndUser(data.licenseId, data.id);
                        if (licenseAssociatesByLicense.value.length === 0) {
                            await selectedLicenseDefault();
                        } else {
                            displayModalLicense.value = true;
                        }
                    } else {
                        // console.log('data', data);
                        loggedIn!(data.name, data, data.email, data.token);

                        setTimeout(() => {
                            // eslint-disable-next-line no-restricted-globals
                            location.reload();
                        }, 500);
                        loadingButton.value = false;

                    }
                }
            })
            .catch((error) => {
                console.log('error', error);
                toast.add({
                    severity: 'error',
                    detail: 'Los datos ingresados no son correctos',
                    life: 3000,
                    group: 'login',
                });
                loadingButton.value = false;
            });
        // .finally(() => {
        //     loadingButton.value = false;
        // });
    }
};

const passwordRecovery = async () => {
    setTimeout(() => {
        router.push('/recupera-tu-contrasena');
    }, 300);
};

const saveAcceptTerms = async () => {
    displayModalTC.value = false;

    const data = dataInit.value;

    const user = new User();
    user.id = data.id;

    await UserAPI.updateAcceptTermsByUser(user);
    displayModalTODP.value = true;

};

const declineAcceptTerms = async () => {

    displayModalTC.value = false;
    toast.add({
        severity: 'warn',
        detail: 'Debe aceptar los términos y condiciones para continuar',
        life: 3000,
        group: 'login',
    });
    if (!federatedCode) {
        router.push('/login');
    } else {
        redirectToFederatedUrl();
    }
};

const saveAcceptPersonalDataProtection = async () => {
    displayModalTODP.value = false;

    const data = dataInit.value;
    loggedIn!(data.name, data, data.email, data.token);

    const user = new User();
    user.id = data.id;
    user.acceptPersonalDataProtection = 1;

    await UserAPI.updateAcceptPersonalDataProtection(user);

    toast.add({
        severity: 'success',
        detail: 'Acepto la proteccion de datos personales y recibira notificciones',
        life: 3000,
        group: 'login',
    });
    toast.add({ severity: 'success', detail: 'Inicio de sesión autorizado', life: 3000, group: 'login' });
    setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 500);


};

const declineAcceptPersonalDataProtection = async () => {
    displayModalTODP.value = false;
    const data = dataInit.value;
    loggedIn!(data.name, data, data.email, data.token);
    toast.add({
        severity: 'warn',
        detail: 'No acepto la proteccion de datos personales y no recibira notificciones',
        life: 3000,
        group: 'login',
    });
    toast.add({ severity: 'success', detail: 'Inicio de sesión autorizado', life: 3000, group: 'login' });
    setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 500);
};

const redirectToFederatedUrl = async () => {
    window.location.href = 'https://golen.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=71hgrjcgcvijuecbmn1kmhhpim&response_type=code&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A5000%2Fcognito';
};


const selectedLicenseDefault = async () => {
    const data = dataInit.value;
    data.accessTypeId = data.accessTypeId === 6 ? 3 : 4;
    loggedIn!(data.name, data, data.email, data.token);

    setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 500);
    loadingButton.value = false;
};

const selectedLicenseAssociate = async () => {
    const data = dataInit.value;
    data.accessTypeId = data.accessTypeId === 6 ? 3 : 4;
    data.licenseId = selectedLicense.value;
    loggedIn!(data.name, data, data.email, data.token);

    setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 500);
    loadingButton.value = false;
};

</script>

<style scoped>

</style>