import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "exception-body error" }
const _hoisted_2 = { class: "exception-content" }
const _hoisted_3 = { class: "text-2xl text-red-900" }
const _hoisted_4 = { class: "text-xl font-bold" }

import { ref, onMounted, inject } from 'vue';
import router from '@/router';
import Store from '@/data/Store';


export default /*@__PURE__*/_defineComponent({
  __name: 'Error',
  setup(__props) {

const {
    logOut,
} = inject<Store>('store', {});

const code = ref('');
const pathName = ref('');
const fullPath = ref('');
const messageTitle = ref('d');
const messageBody = ref('d');
const labelButton = ref('d');
const pathToRedirect = ref('');

const logOutLocal = () => {
    if (typeof logOut === 'function') {
        logOut!();

        // Uso de router.replace para evitar el retorno al historial anterior
        router.replace('/login').catch(err => {
            // Manejar errores
            console.error(err);
        });
    }
};

onMounted(() => {
    console.log('Se detecto error');
    code.value = router.currentRoute.value.query.code?.toString() || '';
    pathName.value = router.currentRoute.value.path;
    fullPath.value = router.currentRoute.value.fullPath;
    if (code.value === '0') {
        messageTitle.value = 'No esta autorizado';
        messageBody.value = 'Se cerro la sesión';
        labelButton.value = 'Volver a Iniciar Sesión';
        logOutLocal();
        pathToRedirect.value = '/login';
    } else {
        messageTitle.value = 'Error';
        messageBody.value = 'Se regresa al inicio';
        labelButton.value = 'Volver al Inicio';
        pathToRedirect.value = '/';
    }

});

const goPageAfterError = async () => {
    await router.push({ path: pathToRedirect.value });
};



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "exception-panel" }, null, -1)),
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("img", {
        src: "layout/images/logo-h100b.png",
        alt: "babylon-layout"
      }, null, -1)),
      _cache[2] || (_cache[2] = _createElementVNode("h1", null, [
        _createElementVNode("span", { class: "exception-name" }, "ERROR"),
        _createTextVNode(" OCCURRIDO")
      ], -1)),
      _createElementVNode("div", null, [
        _createElementVNode("p", null, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(messageTitle.value), 1),
          _cache[0] || (_cache[0] = _createElementVNode("br", null, null, -1)),
          _createElementVNode("span", _hoisted_4, _toDisplayString(messageBody.value), 1)
        ])
      ]),
      _createElementVNode("a", {
        onClick: goPageAfterError,
        style: {"cursor":"pointer"}
      }, _toDisplayString(labelButton.value), 1)
    ])
  ]))
}
}

})