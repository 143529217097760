import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withModifiers as _withModifiers, withKeys as _withKeys, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "login-body",
  style: {"background-color":"#0B2142"}
}
const _hoisted_2 = { class: "login-content" }
const _hoisted_3 = { class: "login-input-wrapper" }
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { class: "login-input-wrapper" }

import {
    computed, inject, reactive, ref,
} from 'vue';
import { email, helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import Store from '@/data/Store';
import router from '@/router';
import UserDto from '@/data/entity/dto/UserDto';
import UserAPI from '@/data/api/UserAPI';


export default /*@__PURE__*/_defineComponent({
  __name: 'PasswordRecovery',
  setup(__props) {

const {
    auth,
} = inject<Store>('store', {});

if (auth?.authenticate) {
    router.push('/');
}

const toast = useToast();

const loadingButton = ref(false);

const formState = reactive({
    email: '',
});

const rules = computed(() => ({
    email: {
        email: helpers.withMessage('Email no es válido', email),
        required: helpers.withMessage('Email es obligatorio', required),
    },
}));

const v$ = useVuelidate(rules, formState, { $autoDirty: true });

const btnAcceptDisable = computed(() => {
    if (v$.value.$invalid) {
        return true;
    }
    return false;
});

const returnLogin = async () => {
    setTimeout(() => {
        router.push('/login');
    }, 300);
};

const passwordRecovery = async () => {
    await v$.value.$validate();

    if (v$.value.email.$error) {
        return;
    }

    loadingButton.value = true;

    const user = new UserDto();
    user.email = formState.email;

    UserAPI.PasswordRecovery(user)
        .then((data) => {
            toast.add({
                severity: 'success',
                summary: data.message,
                life: 3000,
                group: 'recover-password',
            });

            setTimeout(() => {
                router.push('/login');
            }, 1000);
        })
        .catch((error) => {
            console.log(error.message);
            toast.add({
                severity: 'error',
                summary: 'Los datos ingresados no son correctos',
                life: 3000,
                group: 'recover-password',
            });
        })
        .finally(() => {
            loadingButton.value = false;
        });
};


return (_ctx: any,_cache: any) => {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast, { group: "recover-password" }),
    _createElementVNode("div", _hoisted_1, [
      _cache[5] || (_cache[5] = _createElementVNode("div", { class: "login-panel" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _cache[2] || (_cache[2] = _createElementVNode("img", {
          src: "layout/images/logo-h100.png",
          alt: "Golen"
        }, null, -1)),
        _cache[3] || (_cache[3] = _createElementVNode("h1", null, [
          _createElementVNode("span", null, "Recupera tu contraseña"),
          _createTextVNode(" golen")
        ], -1)),
        _cache[4] || (_cache[4] = _createElementVNode("p", null, "Bienvenido, utilice el formulario para recuperar tu contraseña.", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_InputText, {
            modelValue: _unref(v$).email.$model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).email.$model) = $event)),
            onKeyup: _withKeys(_withModifiers(passwordRecovery, ["prevent"]), ["enter"]),
            placeholder: "Ingrese el correo electronico"
          }, null, 8, ["modelValue", "onKeyup"]),
          _cache[1] || (_cache[1] = _createElementVNode("i", { class: "pi pi-user" }, null, -1))
        ]),
        ((_unref(v$).email.$errors.length>0))
          ? (_openBlock(), _createElementBlock("small", _hoisted_4, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$).email.$errors, (error) => {
                return (_openBlock(), _createElementBlock("p", {
                  key: error.$uid
                }, _toDisplayString(error.$message), 1))
              }), 128))
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", { class: "login-input-wrapper text-right" }, [
          _createElementVNode("a", {
            onClick: returnLogin,
            class: "font-medium text-white hover:text-gray-400 cursor-pointer"
          }, " <- Regresar al login ")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Button, {
            class: "w-full",
            label: "Recuperar",
            loading: loadingButton.value,
            disabled: btnAcceptDisable.value,
            onClick: passwordRecovery
          }, null, 8, ["loading", "disabled"])
        ])
      ])
    ])
  ], 64))
}
}

})