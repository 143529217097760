import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "route-bar" }
const _hoisted_2 = { class: "route-bar-breadcrumb" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }

import ERouteType from '@/router/ERouteType';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppBreadcrumb',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", null, [
        _createElementVNode("li", null, [
          _createVNode(_component_router_link, {
            to: _unref(ERouteType).HOME_PATH
          }, {
            default: _withCtx(() => _cache[0] || (_cache[0] = [
              _createElementVNode("button", { class: "p-link" }, [
                _createElementVNode("i", { class: "pi pi-home" })
              ], -1)
            ])),
            _: 1
          }, 8, ["to"])
        ]),
        (_ctx.$route.meta.breadcrumb)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.$route.meta.breadcrumb[0].parent)
                ? (_openBlock(), _createElementBlock("li", _hoisted_3, "/"))
                : _createCommentVNode("", true),
              (_ctx.$route.meta.breadcrumb[0].parent)
                ? (_openBlock(), _createElementBlock("li", _hoisted_4, _toDisplayString(_ctx.$route.meta.breadcrumb[0].parent), 1))
                : _createCommentVNode("", true),
              _cache[1] || (_cache[1] = _createElementVNode("li", null, "/", -1)),
              _createElementVNode("li", null, _toDisplayString(_ctx.$route.meta.breadcrumb[0].label), 1)
            ], 64))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})