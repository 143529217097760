import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "login-body",
  style: {"background-color":"#0B2142"}
}
const _hoisted_2 = { class: "login-content" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = { class: "login-input-wrapper" }
const _hoisted_5 = { class: "login-input-wrapper" }
const _hoisted_6 = { class: "flex flex-column gap-4" }
const _hoisted_7 = ["for"]

import { computed, inject, reactive, ref } from 'vue';
import { email, helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import router from '@/router';
import Store from '@/data/Store';
// import global from '@/data/global';
// import ERouteType from '@/router/ERouteType';
import UserAPI from '@/data/api/UserAPI';
import User from '@/data/entity/User';
import UserDto from '@/data/entity/dto/UserDto';
import { useRoute } from 'vue-router';
import LicenseAPI from '@/data/api/LicenseAPI';

const urlTC = 'https://files-golex.s3.us-east-2.amazonaws.com/terms/TC_Usuarios_de_Empresas.pdf';
const urlTODP = 'https://files-golex.s3.us-east-2.amazonaws.com/terms/TC_para_tratamiento_de_datos_opcionales_de_USUARIOS_de_Empresas.pdf';


export default /*@__PURE__*/_defineComponent({
  __name: 'Login',
  setup(__props) {

const {
    auth,
    loggedIn,
} = inject<Store>('store', {});

if (auth?.authenticate) {
    console.log('Primero cierre session');
    router.push('/');
    // if (global.isSuperUser()) {
    //     router.push(ERouteType.LICENSE_LIST_PATH);
    // } else if (global.isLawyer()) {
    //     router.push(ERouteType.LEGAL_RULE_LIST_PATH);
    // } else if (global.isAdmin()) {
    //     router.push(ERouteType.USER_LIST_PATH);
    // } else if (global.isUser()) {
    //     router.push(ERouteType.LEGAL_RULE_LIST_BY_CRITERIA_PATH);
    // } else if (global.isLawyerUser()) {
    //     router.push(ERouteType.LEGAL_RULE_LIST_BY_CRITERIA_PATH);
    // }
}

const federatedCode = useRoute().query?.code as string;
const corp = useRoute().query?.corp as string;

const toast = useToast();

const loadingButton = ref(false);

const displayModalTC = ref(false);
const displayModalTODP = ref(false);
const displayModalLicense = ref(false);

const loading = ref(false);
const showForm = ref(true);

const formState = reactive({
    email: '',
    password: '',
});

const acceptTerms = ref(false);
const dataInit = ref<any>();
// const licenseStateId = ref(0);
const isBlocked = ref(false);

const rules = computed(() => ({
    email: {
        email: helpers.withMessage('Email no es válido', email),
    },
    password: {
        required: helpers.withMessage(`El password es obligatorio`, required),
    },
}));

const licenseAssociatesByLicense = ref<any[]>([]);
const selectedLicense = ref<any>(null);

const v$ = useVuelidate(rules, formState, { $autoDirty: true });

const btnAcceptDisable = computed(() => {
    return v$.value.$invalid;

});

const mounted = async () => {

    if (!federatedCode) {
        router.push('/login');
    } else {
        showForm.value = false;
        loading.value = true;
        // console.log('federatedCode', federatedCode);
        // console.log('corp', corp);
        const newData: any = {};
        newData['code'] = federatedCode;
        newData['corp'] = corp;
        // console.log('newData', newData);
        await UserAPI.GetLoginFederatedUser(newData)
            .then((data: any) => {
                // console.log('data', data);
                isBlocked.value = data.isBlocked;
                loadingButton.value = false;
                if (isBlocked.value) {
                    toast.add({
                        severity: 'error',
                        detail: data.message,
                        life: 3000,
                        group: 'login',
                    });
                    return;
                }

                // licenseStateId.value = data.licenseStateId;
                //
                // if (licenseStateId.value === 2) {
                //     toast.add({
                //         severity: 'error',
                //         detail: 'Su licencia se encuentra vencida',
                //         life: 3000,
                //         group: 'login',
                //     });
                //     return;
                // }
                // if (licenseStateId.value === 3) {
                //     toast.add({
                //         severity: 'error',
                //         detail: 'Su licencia se encuentra suspendida',
                //         life: 3000,
                //         group: 'login',
                //     });
                //     return;
                // }

                dataInit.value = data;
                // aceptacion de terminos y condiciones
                acceptTerms.value = data.acceptTerms === 1;
                if (acceptTerms.value === false) {
                    displayModalTC.value = true;
                } else {
                    toast.add({
                        severity: 'success',
                        detail: 'Inicio de sesión autorizado',
                        life: 3000,
                        group: 'login',
                    });
                    loggedIn!(data.name, data, data.email, data.token);

                    setTimeout(() => {
                        // eslint-disable-next-line no-restricted-globals
                        location.reload();
                    }, 500);
                }
            })
            .catch((error) => {
                console.log('error', error);
                toast.add({
                    severity: 'error',
                    detail: 'Los datos ingresados no son correctos',
                    life: 3000,
                    group: 'login',
                });
            });
        loading.value = false;
    }

};

mounted();

const login = async () => {
    loadingButton.value = true;
    await v$.value.$validate();

    if (v$.value.$invalid) {
        // console.log('v$.value', v$.value);
        loadingButton.value = false;
        toast.add({ severity: 'warn', detail: 'Los datos ingresados no son correctos', life: 3000, group: 'login' });
    } else {
        const user = new UserDto();
        user.email = formState.email;
        user.password = formState.password;
        UserAPI.GetLoginUserCognito(user)
            .then(async (data: any) => {
                isBlocked.value = data.isBlocked;
                loadingButton.value = false;
                if (isBlocked.value) {
                    toast.add({
                        severity: 'error',
                        detail: data.message,
                        life: 3000,
                        group: 'login',
                    });
                    return;
                }
                // licenseStateId.value = data.licenseStateId;
                //
                // if (licenseStateId.value === 2) {
                //     toast.add({
                //         severity: 'error',
                //         detail: 'Su licencia se encuentra vencida',
                //         life: 3000,
                //         group: 'login',
                //     });
                //     return;
                // }
                // if (licenseStateId.value === 3) {
                //     toast.add({
                //         severity: 'error',
                //         detail: 'Su licencia se encuentra suspendida',
                //         life: 3000,
                //         group: 'login',
                //     });
                //     return;
                // }

                dataInit.value = data;
                // aceptacion de terminos y condiciones
                acceptTerms.value = data.acceptTerms === 1;
                if (acceptTerms.value === false) {
                    displayModalTC.value = true;
                } else {
                    toast.add({
                        severity: 'success',
                        detail: 'Inicio de sesión autorizado',
                        life: 3000,
                        group: 'login',
                    });

                    if (data.accessTypeId === 6 || data.accessTypeId === 7) {
                        licenseAssociatesByLicense.value = await LicenseAPI.GetLicenseAssociatesByLicenseAndUser(data.licenseId, data.id);
                        if (licenseAssociatesByLicense.value.length === 0) {
                            await selectedLicenseDefault();
                        } else {
                            displayModalLicense.value = true;
                        }
                    } else {
                        // console.log('data', data);
                        loggedIn!(data.name, data, data.email, data.token);

                        setTimeout(() => {
                            // eslint-disable-next-line no-restricted-globals
                            location.reload();
                        }, 500);
                        loadingButton.value = false;

                    }
                }
            })
            .catch((error) => {
                console.log('error', error);
                toast.add({
                    severity: 'error',
                    detail: 'Los datos ingresados no son correctos',
                    life: 3000,
                    group: 'login',
                });
                loadingButton.value = false;
            });
        // .finally(() => {
        //     loadingButton.value = false;
        // });
    }
};

const passwordRecovery = async () => {
    setTimeout(() => {
        router.push('/recupera-tu-contrasena');
    }, 300);
};

const saveAcceptTerms = async () => {
    displayModalTC.value = false;

    const data = dataInit.value;

    const user = new User();
    user.id = data.id;

    await UserAPI.updateAcceptTermsByUser(user);
    displayModalTODP.value = true;

};

const declineAcceptTerms = async () => {

    displayModalTC.value = false;
    toast.add({
        severity: 'warn',
        detail: 'Debe aceptar los términos y condiciones para continuar',
        life: 3000,
        group: 'login',
    });
    if (!federatedCode) {
        router.push('/login');
    } else {
        redirectToFederatedUrl();
    }
};

const saveAcceptPersonalDataProtection = async () => {
    displayModalTODP.value = false;

    const data = dataInit.value;
    loggedIn!(data.name, data, data.email, data.token);

    const user = new User();
    user.id = data.id;
    user.acceptPersonalDataProtection = 1;

    await UserAPI.updateAcceptPersonalDataProtection(user);

    toast.add({
        severity: 'success',
        detail: 'Acepto la proteccion de datos personales y recibira notificciones',
        life: 3000,
        group: 'login',
    });
    toast.add({ severity: 'success', detail: 'Inicio de sesión autorizado', life: 3000, group: 'login' });
    setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 500);


};

const declineAcceptPersonalDataProtection = async () => {
    displayModalTODP.value = false;
    const data = dataInit.value;
    loggedIn!(data.name, data, data.email, data.token);
    toast.add({
        severity: 'warn',
        detail: 'No acepto la proteccion de datos personales y no recibira notificciones',
        life: 3000,
        group: 'login',
    });
    toast.add({ severity: 'success', detail: 'Inicio de sesión autorizado', life: 3000, group: 'login' });
    setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 500);
};

const redirectToFederatedUrl = async () => {
    window.location.href = 'https://golen.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=71hgrjcgcvijuecbmn1kmhhpim&response_type=code&scope=email+openid&redirect_uri=http%3A%2F%2Flocalhost%3A5000%2Fcognito';
};


const selectedLicenseDefault = async () => {
    const data = dataInit.value;
    data.accessTypeId = data.accessTypeId === 6 ? 3 : 4;
    loggedIn!(data.name, data, data.email, data.token);

    setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 500);
    loadingButton.value = false;
};

const selectedLicenseAssociate = async () => {
    const data = dataInit.value;
    data.accessTypeId = data.accessTypeId === 6 ? 3 : 4;
    data.licenseId = selectedLicense.value;
    loggedIn!(data.name, data, data.email, data.token);

    setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
    }, 500);
    loadingButton.value = false;
};


return (_ctx: any,_cache: any) => {
  const _component_Loading = _resolveComponent("Loading")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_vue_pdf_app = _resolveComponent("vue-pdf-app")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _component_RadioButton = _resolveComponent("RadioButton")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Loading, {
        active: loading.value,
        color: "#eeeee4",
        "background-color": "#0B2142",
        loader: "Spinner"
      }, null, 8, ["active"]),
      _cache[11] || (_cache[11] = _createElementVNode("div", { class: "login-panel" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _cache[9] || (_cache[9] = _createElementVNode("img", {
          src: "layout/images/logo-h100.png",
          alt: "Golen"
        }, null, -1)),
        _cache[10] || (_cache[10] = _createElementVNode("h1", null, [
          _createElementVNode("span", null, "Inicia sesión en"),
          _createTextVNode(" golen - peru")
        ], -1)),
        _withDirectives(_createElementVNode("div", null, [
          _cache[8] || (_cache[8] = _createElementVNode("p", null, "Bienvenido, utilice el formulario para iniciar sesión.", -1)),
          _createElementVNode("div", {
            class: _normalizeClass(["login-input-wrapper", {'p-error':_unref(v$)['email'].$errors.length>0}])
          }, [
            _createVNode(_component_InputText, {
              modelValue: _unref(v$)["email"].$model,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$)["email"].$model) = $event)),
              placeholder: "Correo electronico"
            }, null, 8, ["modelValue"]),
            _cache[6] || (_cache[6] = _createElementVNode("i", { class: "pi pi-user" }, null, -1)),
            ((_unref(v$)["email"].$errors.length>0))
              ? (_openBlock(), _createElementBlock("small", _hoisted_3, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(v$)["email"].$errors, (error) => {
                    return (_openBlock(), _createElementBlock("span", {
                      key: error.$uid
                    }, _toDisplayString(error.$message), 1))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ], 2),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_InputText, {
              type: "password",
              modelValue: _unref(v$)["password"].$model,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$)["password"].$model) = $event)),
              placeholder: "Password"
            }, null, 8, ["modelValue"]),
            _cache[7] || (_cache[7] = _createElementVNode("i", { class: "pi pi-lock" }, null, -1))
          ]),
          _createElementVNode("div", { class: "login-input-wrapper text-right" }, [
            _createElementVNode("a", {
              onClick: passwordRecovery,
              class: "font-medium text-white hover:text-gray-400 cursor-pointer"
            }, " Olvidaste tu contraseña? ")
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_Button, {
              class: "w-full",
              label: "Ingresar",
              disabled: btnAcceptDisable.value,
              loading: loadingButton.value,
              onClick: login
            }, null, 8, ["disabled", "loading"])
          ])
        ], 512), [
          [_vShow, showForm.value]
        ])
      ])
    ]),
    _createVNode(_component_Toast, { group: "login" }),
    _createVNode(_component_Dialog, {
      header: "TÉRMINOS Y CONDICIONES",
      visible: displayModalTC.value,
      "onUpdate:visible": _cache[2] || (_cache[2] = ($event: any) => ((displayModalTC).value = $event)),
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '50vw'},
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "No Aceptar",
          icon: "pi pi-times",
          onClick: declineAcceptTerms,
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Aceptar Terminos",
          icon: "pi pi-check",
          onClick: saveAcceptTerms,
          autofocus: ""
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_vue_pdf_app, {
          id: "tc",
          style: {"height":"100vh"},
          pdf: urlTC
        })
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      header: "TRATAMIENTO OPCIONAL DE DATOS PERSONALES",
      visible: displayModalTODP.value,
      "onUpdate:visible": _cache[3] || (_cache[3] = ($event: any) => ((displayModalTODP).value = $event)),
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '50vw'},
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "No Aceptar",
          icon: "pi pi-times",
          onClick: declineAcceptPersonalDataProtection,
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Aceptar Tratamiento de Datos",
          icon: "pi pi-check",
          onClick: saveAcceptPersonalDataProtection,
          autofocus: ""
        })
      ]),
      default: _withCtx(() => [
        _createVNode(_component_vue_pdf_app, {
          id: "todp",
          style: {"height":"100vh"},
          pdf: urlTODP
        })
      ]),
      _: 1
    }, 8, ["visible"]),
    _createVNode(_component_Dialog, {
      header: "Licencias Asociadas Disponibles",
      visible: displayModalLicense.value,
      "onUpdate:visible": _cache[5] || (_cache[5] = ($event: any) => ((displayModalLicense).value = $event)),
      breakpoints: {'960px': '75vw', '640px': '90vw'},
      style: {width: '30vw'},
      closable: false,
      modal: true
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_Button, {
          label: "Licencia por defecto",
          icon: "pi pi-times",
          onClick: selectedLicenseDefault,
          class: "p-button-text"
        }),
        _createVNode(_component_Button, {
          label: "Licencia Seleccionada",
          icon: "pi pi-check",
          disabled: !selectedLicense.value,
          onClick: selectedLicenseAssociate,
          autofocus: ""
        }, null, 8, ["disabled"])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(licenseAssociatesByLicense.value, (licenseAssociate) => {
            return (_openBlock(), _createElementBlock("div", {
              key: licenseAssociate.id,
              class: "flex items-center"
            }, [
              _createVNode(_component_RadioButton, {
                modelValue: selectedLicense.value,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((selectedLicense).value = $event)),
                inputId: licenseAssociate.id,
                value: licenseAssociate.id
              }, null, 8, ["modelValue", "inputId", "value"]),
              _createElementVNode("label", {
                for: licenseAssociate.id,
                class: "ml-2"
              }, _toDisplayString(licenseAssociate.identificationFiscal + ' - ' + licenseAssociate.businessName), 9, _hoisted_7)
            ]))
          }), 128))
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})