import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-footer" }
const _hoisted_2 = { class: "grid" }
const _hoisted_3 = { class: "col-6 footer-icons" }

import ERouteType from '@/router/ERouteType';


export default /*@__PURE__*/_defineComponent({
  __name: 'AppFooter',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[2] || (_cache[2] = _createElementVNode("div", { class: "col-6" }, [
        _createElementVNode("a", {
          href: "/",
          class: "logo-container"
        }, [
          _createElementVNode("img", {
            src: "layout/images/logo-h100b.png",
            alt: "babylon-layout"
          })
        ])
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: _unref(ERouteType).HOME_PATH
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createElementVNode("button", { class: "p-link" }, [
              _createElementVNode("i", { class: "pi pi-home" })
            ], -1)
          ])),
          _: 1
        }, 8, ["to"]),
        _cache[1] || (_cache[1] = _createStaticVNode("<a href=\"https://golencompliance.com/\" target=\"_blank\"><button class=\"p-link\"><i class=\"pi pi-globe\"></i></button></a><a href=\"mailto:soporte@golencompliance.com\"><button class=\"p-link\"><i class=\"pi pi-envelope\"></i></button></a>", 2))
      ])
    ])
  ]))
}
}

})