import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, withKeys as _withKeys, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "login-body",
  style: {"background-color":"#0B2142"}
}
const _hoisted_2 = { class: "login-content" }
const _hoisted_3 = { class: "login-input-wrapper" }
const _hoisted_4 = { class: "login-input-wrapper" }
const _hoisted_5 = { class: "login-input-wrapper" }

import {
  computed, inject, reactive, ref,
} from 'vue';
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useToast } from 'primevue/usetoast';
import { useRoute } from 'vue-router';
import Store from '@/data/Store';
import router from '@/router';
import UserDto from '@/data/entity/dto/UserDto';
import UserAPI from '@/data/api/UserAPI';


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangePassword',
  setup(__props) {

const {
  auth,
} = inject<Store>('store', {});

if (auth?.authenticate) {
    console.log('Primero cierre session');
  router.push('/');
}

const recoveryId = useRoute().query?.code as string;

if (!recoveryId) {
  router.push('/login');
}

const toast = useToast();

const loadingRecovery = ref(false);

const formState = reactive({
  password: '',
  repeatPassword: '',
});

const rules = computed(() => ({
  password: {
    required: helpers.withMessage(`El password es obligatorio`, required),
  },
  repeatPassword: {
    required: helpers.withMessage(`Repetir el password es obligatorio`, required),
  },
}));

const v$ = useVuelidate(rules, formState, { $autoDirty: true });

const btnAcceptDisable = computed(() => {
  if (v$.value.$invalid) {
    return true;
  }

  if (formState.password !== formState.repeatPassword) {
    return true;
  }
  return false;
});

const returnLogin = async () => {
  setTimeout(() => {
    router.push('/login');
  }, 300);
};

const changePassword = async () => {
  loadingRecovery.value = true;

  const user = new UserDto();
  user.token = recoveryId;
  user.password = formState.password;
  user.repeatPassword = formState.repeatPassword;

  UserAPI.ChangePassword(user)
    .then((data) => {
        console.log('data', data);
        toast.add({
          severity: 'success',
          summary: 'Cambio de contraseña',
          detail: 'La contraseña se ha cambiado correctamente',
          life: 3000,
          group: 'change-password',
        });
      setTimeout(() => {
        router.push('/login');
      }, 1000);
    })
    .catch((error) => {
        console.log('error', error);
      toast.add({
        severity: 'error',
        summary: 'Cambio de contraseña',
        life: 3000,
        group: 'change-password',
      });
    })
    .finally(() => {
      loadingRecovery.value = false;
    });
};


return (_ctx: any,_cache: any) => {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Toast, { group: "change-password" }),
    _createElementVNode("div", _hoisted_1, [
      _cache[7] || (_cache[7] = _createElementVNode("div", { class: "login-panel" }, null, -1)),
      _createElementVNode("div", _hoisted_2, [
        _cache[4] || (_cache[4] = _createElementVNode("img", {
          src: "layout/images/logo-h100.png",
          alt: "Golen"
        }, null, -1)),
        _cache[5] || (_cache[5] = _createElementVNode("h1", null, [
          _createElementVNode("span", null, "Cambia tu contraseña"),
          _createTextVNode(" golen")
        ], -1)),
        _cache[6] || (_cache[6] = _createElementVNode("p", null, "Bienvenido, utilice el formulario para cambiar tu contraseña.", -1)),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_InputText, {
            type: "password",
            modelValue: _unref(v$).password.$model,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(v$).password.$model) = $event)),
            placeholder: "Ingrese tu nueva contraseña",
            required: ""
          }, null, 8, ["modelValue"]),
          _cache[2] || (_cache[2] = _createElementVNode("i", { class: "pi pi-lock" }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_InputText, {
            type: "password",
            onKeyup: _withKeys(changePassword, ["enter"]),
            modelValue: _unref(v$).repeatPassword.$model,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(v$).repeatPassword.$model) = $event)),
            placeholder: "Repite tu contraseña"
          }, null, 8, ["modelValue"]),
          _cache[3] || (_cache[3] = _createElementVNode("i", { class: "pi pi-lock" }, null, -1))
        ]),
        _createElementVNode("div", { class: "login-input-wrapper text-right" }, [
          _createElementVNode("a", {
            onClick: returnLogin,
            class: "font-medium text-white hover:text-gray-400 cursor-pointer"
          }, " <- Regresar al login ")
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_Button, {
            class: "w-full",
            label: "Cambiar",
            disabled: btnAcceptDisable.value,
            loading: loadingRecovery.value,
            onClick: changePassword
          }, null, 8, ["disabled", "loading"])
        ])
      ])
    ])
  ], 64))
}
}

})